import '../styles/StoreStand.css';
import '../styles/MainStyle.css';
import '../styles/Partners.css';
var createReactClass = require('create-react-class');

var StoreStandRating = createReactClass({

    render: function () {
        return (
            <div className="store-stand-rating-container">
                {/* <div className="single-rating">
                    <div className="single-rating-header">
                        U <i> tagu ispod se prikazuje prvo slovo korisnikovog imena
                        <i>L</i>
                        <h4>Lazar</h4>
                        <p>21-03-2021 18:34:04</p>
                        <div className="store-rating">
                            <img src="images/small_img/star-rating.svg" alt="Ikonica za rejting lokala" />
                            <p className="rating">x.x</p>
                        </div>

                    </div>
                    <div className="single-rating-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti exercitationem eveniet
                            dolores consequuntur temporibus, neque odio repudiandae rerum dolore assumenda!
                        </p>
                    </div>
                    <div className="single-rating-footer">
                        <span>
                            <h4>Kvalitet:</h4>
                            <p>x</p>
                        </span>
                        <span>
                            <h4>Jelovnik i cene:</h4>
                            <p>x</p>
                        </span>
                        <span>
                            <h4>Usluga:</h4>
                            <p>x</p>
                        </span>
                    </div>
                </div> */}

                {/* Ukoliko nema utisaka, prikazuje se div ispod */}
                <div className="no-ratings">
                    <img src="images/small_img/blogger.svg" alt="Ostavi utisak ikonica" />
                    <p>Još uvek nema utisaka. </p>
                </div>
            </div>
        );
    }
});

export default StoreStandRating