import Loader from '../components/Loader';
import OrderRating from '../model/orderRating';
import '../styles/Feedback.css';
import MessageBox from './MessageBox';
var createReactClass = require('create-react-class');

var Feedback = createReactClass({
    getInitialState: function () {
        let _hasClient = false;
        let _client = null;
        let _orderId = "";
        if (this.props.order &&
            this.props.order.cart &&
            this.props.order.cart.client &&
            this.props.order.cart.client.id) {
            _hasClient = true;
            _client = this.props.order.cart.client;
            _orderId = this.props.order.orderId
        }
        if (this.props.notification) {
            _orderId = this.props.notification.orderId;
            if (this.props.notification.clientId &&
                this.props.notification.clientName) {
                _hasClient = true;
                _client = {
                    id: this.props.notification.clientId,
                    name: this.props.notification.clientName
                }
            }
        }
        return {
            deliveryRating: "",
            clientRating: _hasClient ? "" : "unknown",
            comment: "",
            loading: false,
            hasClient: _hasClient,
            client: _client,
            orderId: _orderId,
        }
    },
    onClose: function (submitClick) {
        if (!submitClick) {
            if (this.props.onClose) {
                this.props.onClose(false);
            }
            else {
                console.log("On close function is not set!");
            }
        }
        else {
            const orderRating = new OrderRating();
            orderRating.orderId = this.state.orderId;
            if (this.state.hasClient) {
                orderRating.clientId = this.state.client.id;
                orderRating.clientName = this.state.client.name;
            }
            if (this.props.user) {
                orderRating.userEmail = this.props.user.email;
                orderRating.userName = this.props.user.name;
                orderRating.userPhone = this.props.user.phone;
            }
            if (this.props.notification) {
                orderRating.userEmail = this.props.notification.userEmail;
                orderRating.userName = this.props.notification.userName;
                orderRating.userPhone = this.props.notification.userPhone;
            }
            orderRating.deliveryRating = this.state.deliveryRating;
            orderRating.clientRating = this.state.clientRating;
            orderRating.comment = this.state.comment;
            this.props.dbHandler.addOrderRatingDB(orderRating, this.onDbHandlerFinish);
            this.setState({
                loading: true,
            });
        }

    },
    onDbHandlerFinish: function () {
        if (this.props.order) {
            this.props.order.hasRating = true;
        }
        if (this.props.onClose) {
            this.props.onClose(true);
        }
        else {
            console.log("On close function is not set!");
        }
    },
    onChange: function (event) {
        const id = event.target.name;
        const value = event.target.value;
        switch (id) {
            case "question1":
                this.setState({ deliveryRating: value });
                break;
            case "question2":
                this.setState({ clientRating: value });
                break;
            case "question3":
                this.setState({ comment: value });
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <MessageBox
                title="Ocenite uslugu"
                onClick={this.onClose}
                disableButton={this.state.loading || !(this.state.deliveryRating && this.state.clientRating)}
                disableCancel={this.state.loading}
                showCancel={true}
                content={this.state.loading ? <Loader /> :
                    <div className="feedback-container">
                        <div>
                            <p>Na skali od 1-5 kako biste ocenili kvalitet dostave?</p>
                            <form action="" className="question-form" id="question1" onChange={this.onChange}>
                                <div className="form-input">
                                    <input type="radio" name="question1" id="veryBad" value="1" />
                                    <label htmlFor="veryBad" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="veryBad">1</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question1" id="bad" value="2" />
                                    <label htmlFor="bad" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="bad">2</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question1" id="okay" value="3" />
                                    <label htmlFor="okay" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="okay">3</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question1" id="good" value="4" />
                                    <label htmlFor="good" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="good">4</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question1" id="veryGood" value="5" />
                                    <label htmlFor="veryGood" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="veryGood">5</label>
                                </div>
                            </form>
                        </div>
                        {this.state.hasClient ? <div>
                            <p>Na skali od 1-5 kako biste ocenili kvalitet porudžbine iz lokala?</p>
                            <form action="" className="question-form" id="question2" onChange={this.onChange}>
                                <div className="form-input">
                                    <input type="radio" name="question2" id="veryBadq2" value="1" />
                                    <label htmlFor="veryBadq2" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="veryBadq2">1</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question2" id="badq2" value="2" />
                                    <label htmlFor="badq2" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="badq2">2</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question2" id="okayq2" value="3" />
                                    <label htmlFor="okayq2" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="okayq2">3</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question2" id="goodq2" value="4" />
                                    <label htmlFor="goodq2" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="goodq2">4</label>
                                </div>
                                <div className="form-input">
                                    <input type="radio" name="question2" id="veryGoodq2" value="5" />
                                    <label htmlFor="veryGoodq2" className="input-black-circle">
                                        <i></i>
                                    </label>
                                    <label htmlFor="veryGoodq2">5</label>
                                </div>
                            </form>
                        </div> : null}
                        <div>
                            <p>Komentar porudžbine:</p>
                            <textarea name="question3" id="question3" cols="40" rows="5"
                                placeholder="Pohvale, primedbe i predlozi za lokal"
                                onChange={this.onChange} value={this.state.comment}></textarea>
                        </div>
                    </div>}
            />
        )
    }
});




export default Feedback
