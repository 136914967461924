import '../styles/InputField.css';

function InputField(type, placeholder, value, id, onChange, onClick) {
    return (
        <div className="input-field-container">
            <form action="">
                <input type={type} value={value} placeholder={placeholder} id={id} maxlength={(type === "text" ? 20 : 30)} required
                    onChange={onChange} />
            </form>
            <div className="input-field-button-container" >
                <button className="input-field-cancel" id="cancel" onClick={onClick.bind(this, "cancel", id)}></button>
                <button className="input-field-confirm" id="confirm" onClick={onClick.bind(this, "confirm", id)}></button>
            </div>
        </div>
    )
}

export default InputField;
