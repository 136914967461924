class WorkingTimeHelper {
    static getWorkingTimeForCurrentDay(workingTimes) {
        let dayNumber = this.getCurrentDayIndex();
        let returnWorkingTime = null;
        workingTimes.forEach(wTimeObj => {
            if (wTimeObj.applied_days[dayNumber]) {
                returnWorkingTime = wTimeObj
            }
        });
        return returnWorkingTime;
    }

    static getDayNameByIndex(index) {
        switch (Number(index)) {
            case 0:
                return "Ponedeljak";
            case 1:
                return "Utorak";
            case 2:
                return "Sreda";
            case 3:
                return "Četvrtak";
            case 4:
                return "Petak";
            case 5:
                return "Subota";
            case 6:
                return "Nedelja";

            default:
                return "Nedelja";
        }
    }

    static getCurrentDayIndex() {
        let currentDate = new Date();
        let dayNumber = currentDate.getDay() - 1;
        // converting from first Sunday to first Monday!
        if (dayNumber < 0) {
            dayNumber = 6
        }
        return dayNumber;
    }
}

export default WorkingTimeHelper