/* eslint-disable react/no-direct-mutation-state */
import '../styles/DeliveryModal.css';
var createReactClass = require('create-react-class');

var ModalBodyComment = createReactClass({
    getInitialState: function () {
        let _placeholder = "Navedite Vaš zahtev u komentaru.";
        if (this.props.Order && !this.props.Order.cart.isEmpty()) {
            _placeholder = "Komentar za lokal ili dostavu.";
        }
        if (this.props.placeholder !== "") {
            _placeholder = this.props.placeholder;
        }
        return ({
            order: this.props.Order,
            comment: this.props.Order.comment,
            placeholder: _placeholder,
        });
    },
    onInputChange: function (event) {
        let val = event.target.value;
        let id = event.target.id;
        switch (id) {
            case "comment":
                this.state.order.comment = val;
                this.setState({ comment: val });
                break;
            default:
                break;
        }
        if (this.props.onChange) {
            this.props.onChange();
        }
    },
    render: function () {
        return (
            <div className="modal-body-form">
                <div className="modal-body-comment">
                    <h2>Dodatni komentar {this.props.optional ? <>(opciono)</> : null}:</h2>
                    <label htmlFor="comment"></label>
                    <textarea onChange={this.onInputChange} name="message" id="comment" className="text-area" cols="30" rows="7"
                        placeholder={this.state.placeholder}>{this.state.comment}</textarea>
                </div>
            </div>
        )
    }
})

export default ModalBodyComment;