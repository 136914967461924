import '../styles/LoaderSmall.css';
import React from 'react';

function LoaderSmall() {
    return (
        <div class="ml-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoaderSmall;
