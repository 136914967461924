/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
export class WorkingTime {
  start_hours: number;
  start_minutes: number;
  end_hours: number;
  end_minutes: number;
  delivery_start_hours: number;
  delivery_start_minutes: number;
  delivery_end_hours: number;
  delivery_end_minutes: number;
  applied_days: { [key: number]: boolean };

  constructor() {
    this.start_hours = 0;
    this.start_minutes = 0;
    this.end_hours = 0;
    this.end_minutes = 0;
    this.delivery_start_hours = 0;
    this.delivery_start_minutes = 0;
    this.delivery_end_hours = 0;
    this.delivery_end_minutes = 0;
    this.applied_days = {};
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.start_hours) {
        this.start_hours = jsonObject.start_hours;
      }
      if (jsonObject.start_minutes) {
        this.start_minutes = jsonObject.start_minutes;
      }
      if (jsonObject.end_hours) {
        this.end_hours = jsonObject.end_hours;
      }
      if (jsonObject.end_minutes) {
        this.end_minutes = jsonObject.end_minutes;
      }
      if (jsonObject.delivery_start_hours) {
        this.delivery_start_hours = jsonObject.delivery_start_hours;
      }
      if (jsonObject.delivery_start_minutes) {
        this.delivery_start_minutes = jsonObject.delivery_start_minutes;
      }
      if (jsonObject.delivery_end_hours) {
        this.delivery_end_hours = jsonObject.delivery_end_hours;
      }
      if (jsonObject.delivery_end_minutes) {
        this.delivery_end_minutes = jsonObject.delivery_end_minutes;
      }
      if (jsonObject.applied_days) {
        this.applied_days = jsonObject.applied_days;
      }
    }
  }

  toJason(): any {
    return {
      start_hours: this.start_hours,
      start_minutes: this.start_minutes,
      end_hours: this.end_hours,
      end_minutes: this.end_minutes,
      delivery_start_hours: this.delivery_start_hours,
      delivery_start_minutes: this.delivery_start_minutes,
      delivery_end_hours: this.delivery_end_hours,
      delivery_end_minutes: this.delivery_end_minutes,
      applied_days: this.applied_days,
    };
  }

  toString(delivery = false): string {
    if (delivery) {
      return "" + this.delivery_start_hours + " : " + this.delivery_start_minutes + " - "
        + this.delivery_end_hours + " : " + this.delivery_end_minutes + " ";
    }
    else {
      return "" + this.start_hours + " : " + this.start_minutes + " - "
        + this.end_hours + " : " + this.end_minutes + " ";
    }
  }

  isCurrentlyWorking(): boolean {
    const dateObj = new Date();
    const currentHours = dateObj.getHours();
    const currentMinutes = dateObj.getMinutes();

    if (currentHours > Number(this.delivery_start_hours) &&
      currentHours < Number(this.delivery_end_hours)) {
      return true;
    }
    if (Number(this.delivery_start_hours) > Number(this.delivery_end_hours)) {
      if (currentHours > Number(this.delivery_start_hours) ||
        currentHours < Number(this.delivery_end_hours)) {
        return true;
      }
    }
    if (currentHours === Number(this.delivery_end_hours)) {
      if (currentMinutes <= Number(this.delivery_end_minutes)) {
        return true;
      }
    }
    if (currentHours === Number(this.delivery_start_hours)) {
      if (currentMinutes >= Number(this.delivery_start_minutes)) {
        return true;
      }
    }

    return false;
  }


  /**
   * Returns object/map where key is a day number (0-6) and value is WorkingTime object.
   * If WorkingTime is null, client is not working for that day.
   * @param workingTimes 
   * @returns 
   */
  static getWeekSchedular(workingTimes: Array<WorkingTime>): { [key: number]: WorkingTime } {
    let retMap: any = {};
    for (let index = 0; index < 7; index++) {
      let dayTime = null;
      workingTimes.forEach((wTime: WorkingTime) => {
        if (wTime.applied_days[index]) {
          dayTime = wTime;
        }
      });
      retMap[index] = dayTime;
    }

    return retMap;
  }
}

export default WorkingTime;
