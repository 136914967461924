import '../styles/GoUpButton.css';
import '../styles/SiteTestDiv.css';
import createReactClass from 'create-react-class';

var GoUpButton = createReactClass({
    getInitialState: function () {
        return (
            {
                is_visible: false,
            }
        )
    },
    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility();
        });
    },
    toggleVisibility() {
        if (window.pageYOffset > 300) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    },
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },
    render: function () {
        const { is_visible } = this.state;
        return (
            <div className="go-up-button-container">
                {is_visible && (
                    <button className="go-up-button" onClick={() => this.scrollToTop()} >
                        <img src="./images/regular/up.svg" alt="Sajt test dugme" />
                    </button>
                )}
            </div>
        );
    }
});

export default GoUpButton
