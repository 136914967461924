import '../styles/StoreStand.css';
import '../styles/MainStyle.css';
import '../styles/Partners.css';
import WorkingTime from '../model/workingTime';
import WorkingTimeHelper from "../common/WorkingTimeHelper";
var createReactClass = require('create-react-class');

var StoreStandInformation = createReactClass({
    getInitialState: function () {
        return {
            clientTypes: this.props.clientTypes,
            selectedClientType: this.props.selectedClientType,
            client: this.props.client,
            currentDayIndex: WorkingTimeHelper.getCurrentDayIndex()
        }
    },
    render: function () {
        const weekSchedular = WorkingTime.getWeekSchedular(this.state.client.workingTime);
        return (
            <div className="store-stand-informations-container">
                <div className="address-and-work-hours">
                    <div className="work-hours">
                        <h3>Radno vreme</h3>
                        <div className="work-days border-radius">
                            {Object.keys(weekSchedular).map((workingTimeIndex) => {
                                return (
                                    weekSchedular[workingTimeIndex] ?
                                        <span className={(Number(workingTimeIndex) === Number(this.state.currentDayIndex) ? "selected-day" : "")}>
                                            <p>{WorkingTimeHelper.getDayNameByIndex(workingTimeIndex)}</p>
                                            <p>{weekSchedular[workingTimeIndex].toString()}</p>
                                        </span> :
                                        <span className={(Number(workingTimeIndex) === Number(this.state.currentDayIndex) ? "selected-day" : "")}>
                                            <p>{WorkingTimeHelper.getDayNameByIndex(workingTimeIndex)}</p>
                                            <p>Ne radi</p>
                                        </span>
                                )
                            })}
                            {/* Not supported yet 
                            <p className="shown-address">Prikazuje se radno vreme za adresu:
                                <span>Jevrejska 1, Novi Sad</span>
                            </p> */}

                        </div>

                    </div>
                    <div className="address">
                        <form action="" className="store-stand-combobox">
                            <label for="address-combo">
                                <h3>Adrese</h3>
                            </label>
                            <select name="address-combo" id="address-combo">
                                {this.state.client.locations.map((address, index) => {
                                    return (
                                        <option value={("Adresa" + index)}>{address.address}</option>
                                    )
                                })}
                            </select>
                        </form>
                        <div className="address-location border-radius">
                            <img src="./images/testImages/mapsns.png" alt="Google maps" />
                        </div>
                    </div>

                </div>
                <div className="informations">
                    <h3>Informacije</h3>
                    <p>{this.state.client.info}</p>
                </div>
                {/* <div className="rating-group">
                    <div className="rating">
                        <figure class="chart-two animate">
                            <svg role="img" xmlns="http://www.w3.org/2000/svg">
                                <circle class="circle-background" />
                                <circle class="circle-foreground" />
                            </svg>
                            <figcaption></figcaption>
                        </figure>
                        <p>Kvalitet</p>
                    </div>
                    <div className="rating">
                        <figure class="chart-two animate">
                            <svg role="img" xmlns="http://www.w3.org/2000/svg">
                                <circle class="circle-background" />
                                <circle class="circle-foreground" />
                            </svg>
                            <figcaption></figcaption>
                        </figure>
                        <p>Cena i jelovnik</p>
                    </div>
                    <div className="rating">
                        <figure class="chart-two animate">
                            <svg role="img" xmlns="http://www.w3.org/2000/svg">
                                <circle class="circle-background" />
                                <circle class="circle-foreground" />
                            </svg>
                            <figcaption></figcaption>
                        </figure>
                        <p>Usluga</p>
                    </div>

                </div> */}
            </div>
        )
    }
});

export default StoreStandInformation