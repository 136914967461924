import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/ShoppingCart.css';
import '../styles/MainStyle.css';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
var createReactClass = require('create-react-class');

var ShoppingCartItem = createReactClass({
    getInitialState: function () {
        return {
        }
    },
    getItemName: function () {
        let itemName = this.props.item.name;
        if (Object.values(this.props.item.items).length > 0) {
            Object.values(this.props.item.items).forEach(subitem => {
                if (subitem.selected && subitem.useInName) {
                    itemName = itemName + " " + subitem.name;
                }
            });
        }
        return itemName
    },
    getItemAdditionalInfo: function () {
        let itemInfo = "(";
        let oneAdded = false;
        if (Object.values(this.props.item.items).length > 0) {
            Object.values(this.props.item.items).forEach(subitem => {
                if (subitem.selected && !subitem.useInName) {
                    itemInfo = itemInfo + (oneAdded ? ", " : "") + subitem.name;
                    oneAdded = true;
                }
            });
        }
        return oneAdded ? (itemInfo + ")") : "";
    },
    increaseQuantity: function () {
        this.props.item.itemCount++;
        this.setState({ render: true });
        this.props.onCountChange();
        this.props.cart.generatePackingItems();
    },
    decreaseQuantity: function () {
        if (this.props.item.itemCount > 1) {
            this.props.item.itemCount--;
            this.setState({ render: true });
            this.props.onCountChange();
            this.props.cart.generatePackingItems();
        }
    },
    removeElement: function () {
        this.props.cart.removeItem(this.props.item);
        if (this.props.onRemove) {
            this.props.onRemove();
        }
        else {
            console.log("'onRemove' function is not defined for cart element!");
        }
    },
    render: function () {
        return (
            <div className="shopping-cart-item-container">
                <div className="shopping-cart-item-image">
                    {this.props.item.img.normal ?
                        <img src={this.props.item.img.normal} alt={this.props.item.img.alt} /> :
                        this.props.client && this.props.client.img.normal ?
                            <img src={this.props.client.img.normal} alt="Slika izabranog jela" /> :
                            <img src="../images/shop.png" alt="Slika izabranog jela" />}
                </div>
                <div className="shopping-cart-item-info">
                    <div className="single-cart-item-info-header">
                        <p>{this.getItemName()}</p>
                        {this.props.item.id === "packingFeeItem" ? null :
                            <button className="single-item-header-delete-button" onClick={this.removeElement}>
                                <i><FontAwesomeIcon icon={faTrashAlt} className="delete-shopping-cart-item" /></i>
                                <img src="./images/trash-bin.png" alt="Izbrisi proizvod ikonica" className="flaticon-delete-shopping-cart-item" />
                            </button>}
                    </div>
                    <div className="single-cart-item-additional">
                        <p>{this.getItemAdditionalInfo()}</p>
                    </div>
                    <div className="single-cart-item-info-footer">
                        <p>{this.props.item.getCurrentPrice()} RSD</p>
                        <div className="single-item-info-footer-buttons">
                            {this.props.item.id === "packingFeeItem" ? null :
                                <button className="single-cart-item-info-footer-button" onClick={this.decreaseQuantity}>-</button>}
                            <p className="shopping-cart-quantity">X{this.props.item.itemCount}</p>
                            {this.props.item.id === "packingFeeItem" ? null :
                                <button className="single-cart-item-info-footer-button" onClick={this.increaseQuantity}>+</button>}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
});

export default ShoppingCartItem;