import Cookies from 'js-cookie';

export function clearCartCookies(){
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach(cookieId => {
        const cookieIdSplitArray = cookieId.split("~");
        if (cookieIdSplitArray && cookieIdSplitArray[1] &&
            cookieIdSplitArray[1] === "cartItem") {
                console.log(allCookies[cookieId]);
            Cookies.remove(cookieId);
        } 
    });
}