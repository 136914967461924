import {
    getAuth,
    RecaptchaVerifier,
    onAuthStateChanged,
    signInWithPhoneNumber,
    updateProfile
} from "firebase/auth";
import { clearCartCookies } from "../common/CookieHelper";

let authStateChangeFunction = null;
let userFireBase = null;
let userObj = null;
const auth = getAuth();

class AuthManager {
    constructor() {
        auth.languageCode = 'sr';
        onAuthStateChanged(auth, this.onAuthStateChanged);
    }

    addAuthStateChangeFunction(func) {
        authStateChangeFunction = func;
    }

    async onAuthStateChanged(user) {
        if (user) {
            if (authStateChangeFunction) {
                userFireBase = user;
                authStateChangeFunction(user);
            }
            else {
                console.log("Auth state hanged function is not defined!");
            }
        }
        else {
            if (authStateChangeFunction) {
                userFireBase = null;
                userObj = null;
                authStateChangeFunction(null);
            }
            else {
                console.log("Auth state hanged function is not defined!");
            }
        }
    }

    installInvisibleReCaptcha(containerId, onReCaptchaDone) {
        const reCaptcha = new RecaptchaVerifier(containerId, {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                if (onReCaptchaDone) {
                    onReCaptchaDone();
                }
            }
        }, auth);
        window.recaptchaVerifier = reCaptcha;
        window.recaptchaVerifier.render()
            .then((widgetId) => {
                window.recaptchaWidgetId = widgetId;
            }).catch((error) => {
                console.log("error while rendering recaptcha");
                console.log(error);
            });
    }

    async sendSMStoPhone(phone, invisibleReCaptcha, onJobDone) {
        try {
            const confirmationResult = await signInWithPhoneNumber(auth, phone, invisibleReCaptcha);
            this.confirmationResult = confirmationResult;
            onJobDone(true);
        }
        catch (error) {
            console.log(error);
            onJobDone(false, error);
        }
    }

    sendVerificationCode(code, onJobDone) {
        if (this.confirmationResult) {
            this.confirmationResult.confirm(code).then((result) => {
                onJobDone(result.user);
            }).catch((error) => {
                console.log(error);
                onJobDone(null, error);
            });
        }

    }

    updateUserName(user, name) {
        if (user) {
            updateProfile(user, { displayName: name })
        }
        else {
            console.log("User Name could not be set because it's null!");
        }
    }

    setUserObject(userObject) {
        userObj = userObject;
    }

    getUserObject() {
        return userObj;
    }

    getUserFirebase() {
        return userFireBase;
    }

    async getUserObjectFromDB(dbHandler, onDbFinish) {
        if (userFireBase && userFireBase.phoneNumber) {
            dbHandler.subscribeOnUserChange(userFireBase.phoneNumber, (user) => {
                if (user && user.phone) {
                    userObj = user;
                }
                if (onDbFinish) {
                    onDbFinish();
                }
            })
        }
        // In case when User is logged in with email.
        else if (userFireBase && !userFireBase.phoneNumber) {
            this.logOut();
        }
        else {
            console.log("There is no firebase user.");
        }
    }

    async logOut(onLogoutFunction) {
        clearCartCookies();
        try {
            const userCredential = await auth.signOut();
            if (onLogoutFunction) {
                onLogoutFunction();
            }
            else {
                console.log("Logout function is not set!");
            }
            console.log(userCredential + " user is logged off.");
        }
        catch (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (onLogoutFunction) {
                onLogoutFunction(error);
            }
            else {
                console.log("Logout function is not set!");
            }
            console.log(errorCode);
            console.log(errorMessage);
        }
    }
}

export default AuthManager;