import React from 'react';
import '../styles/QuickPick.css';
import LoaderSmall from '../common/LoaderSmall.jsx';
import { animateScroll as scroll } from 'react-scroll'
var createReactClass = require('create-react-class');

const defaultImg = new Image();
defaultImg.normal = "images/regular/shoe-shop.svg";
defaultImg.alt = "Ikonica za vrstu lokala.";

var QuickPick = createReactClass({
    getInitialState: function () {
        return {
            dbLoading: this.props.dbLoading,
            clientTypes: this.props.clientTypes,
            arrowsShowTrigger: 3, // TODO: Consider setting it to 4 after fixing spaces between cards.
        }
    },
    componentDidUpdate: function (prevProps) {
        if ((this.props.dbLoading !== prevProps.dbLoading) &&
            (this.props.clientTypes !== prevProps.clientTypes)) {
            this.setState({
                dbLoading: this.props.dbLoading,
                clientTypes: this.props.clientTypes
            });
        }
    },
    makeLoadingCards: function () {
        const retArray = [];
        for (let index = 0; index < 5; index++) {
            retArray.push(
                <div className="quick-pick">
                    <div className="quick-icon">
                        {/* <LoaderSmall /> */}
                    </div>
                    <div className="quick-title">
                        <LoaderSmall />
                    </div>
                </div>
            );
        }
        return retArray;
    },
    onArrowClick: function (event) {
        switch (event.currentTarget.id) {
            case "left-arrow":
                scroll.scrollMore(-500, {
                    horizontal: true,
                    offset: 50,
                    duration: 700,
                    // delay: 100,
                    containerId: 'quickPickContainer',
                });
                break;
            case "right-arrow":
                scroll.scrollMore(500, {
                    horizontal: true,
                    offset: 50,
                    duration: 700,
                    // delay: 100,
                    containerId: 'quickPickContainer',
                });
                break;

            default:
                break;
        }
    },
    render: function () {
        return (
            <div className="quick-pick-container" >
                {this.state.clientTypes.length > this.state.arrowsShowTrigger ?
                    <div className="go-left" id="left-arrow" onClick={this.onArrowClick}>
                        <img src="./images/previous.svg" alt="Meni levo" />
                    </div> : null}
                <div className="quick-selection" id="quickPickContainer" >
                    {this.state.dbLoading ? this.makeLoadingCards() :
                        <>
                            {/* <div className="quick-pick" onClick={() => { this.props.onNewDeliveryClick() }}>
                                <div className="quick-icon">
                                    <img src="./images/e-delivery.png" alt="Pošalji kurira na adresu." />
                                </div>
                                <div className="quick-title">
                                    <p>Pošalji kurira na adresu</p>
                                </div>
                            </div> */}
                            {this.state.clientTypes.map((item) => {
                                let img = item.img.normal === "" ? defaultImg : item.img
                                return (
                                    <div className="quick-pick" onClick={() => { this.props.onClick(item.id) }}>
                                        <div className="quick-icon">
                                            <img src={img.normal} alt={img.alt} />
                                        </div>
                                        <div className="quick-title">
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </>}
                </div>
                {this.state.clientTypes.length > this.state.arrowsShowTrigger ?
                    <div className="go-right" id="right-arrow" onClick={this.onArrowClick}>
                        <img src="./images/next.svg" alt="Meni desno" />
                    </div> : null}

            </div >
        )
    }
})

export default QuickPick
