import { disableScroll, enableScroll } from '../common/effectsHelper';
import '../styles/ContactUs.css';
var createReactClass = require('create-react-class');

var ContactUs = createReactClass({
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    render: function () {
        return (
            <div className="contact-us-container">

                <div className="contact-us-content">
                    <img src="./images/regular/saradnja1.svg" alt="Kontakt za saradnju" />
                    <button onClick={this.props.onClose}>X</button>
                </div>

            </div >
        )
    }
});

export default ContactUs
