import enumNotificationType from "./enums/notificationType";
import enumRequestState from "./enums/request_state";

export class Notification {
    id: string;
    type: enumNotificationType;
    date: Date;
    clientId: string;
    clientName: string;
    userEmail: string;
    userName: string;
    userPhone: string;
    orderId: string;
    cancellationReason: string;
    orderState: enumRequestState;
    deliveryTime: number;

    constructor() {
        this.id = "";
        this.type = 0;
        this.date = new Date();
        this.orderId = "";
        this.clientId = "";
        this.clientName = "";
        this.userEmail = "";
        this.userName = "";
        this.userPhone = "";
        this.cancellationReason = "";
        this.orderState = 0;
        this.deliveryTime = 0;
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.type) {
                this.type = jsonObject.type;
            }
            if (jsonObject.date) {
                this.date = jsonObject.date.toDate();
            }
            if (jsonObject.orderId) {
                this.orderId = jsonObject.orderId;
            }
            if (jsonObject.clientId) {
                this.clientId = jsonObject.clientId;
            }
            if (jsonObject.clientName) {
                this.clientName = jsonObject.clientName;
            }
            if (jsonObject.userEmail) {
                this.userEmail = jsonObject.userEmail;
            }
            if (jsonObject.userName) {
                this.userName = jsonObject.userName;
            }
            if (jsonObject.userPhone) {
                this.userPhone = jsonObject.userPhone;
            }
            if (jsonObject.cancellationReason) {
                this.cancellationReason = jsonObject.cancellationReason;
            }
            if (jsonObject.orderState) {
                this.orderState = jsonObject.orderState;
            }
            if (jsonObject.deliveryTime) {
                this.deliveryTime = jsonObject.deliveryTime;
            }
        }
    }

    toJason(): any {
        let retObj: any = {
            id: this.id,
            type: this.type,
            date: this.date,
            orderId: this.orderId,
            clientId: this.clientId,
            clientName: this.clientName,
            userEmail: this.userEmail,
            userName: this.userName,
            userPhone: this.userPhone,
            cancellationReason: this.cancellationReason,
            orderState: this.orderState,
            deliveryTime: this.deliveryTime,
        };

        return retObj;
    }
}

export default Notification;
