import Header from './components/Header';
import PartnerMenu from './components/PartnerMenu';
import QuickPick from "./components/QuickPick";
import Presentation from './components/Presentation';
import CallForOrder from './components/CallForOrder';
import Partners from './components/Partners';
import StoreStand from './components/StoreStand';
import Footer from './components/Footer';
import DBHandler from './database/DatabaseHandler';
import MyProfile from './components/MyProfile.jsx';
import CookieMessage from './common/CookieMessage.jsx';
import DeMangoInfo from './components/DeMangoInfo';
import AuthManager from "./database/AuthManager";
import KoloradoPage from './components/KoloradoPage';
import TestingDiv from './common/TestingDiv';
import Cookies from 'js-cookie';
import './App.css';
import AppConnectionState from './common/app-connection-state';
import AppVersionControl from './components/AppVersionControl';
import packageJason from "../package.json";
// import CountDownModal from './components/countDownModal';
import NotificationManager from './components/NotificationManager';
import GoUpButton from './common/GoUpButton';
import ContactUs from './components/ContactUs';
import TermsConditions from './components/TermsConditions';
import DeliveryModal from './components/DeliveryModal';
var createReactClass = require('create-react-class');

var App = createReactClass({
  getInitialState: function () {
    let _dbHandler = new DBHandler();
    let _authManager = new AuthManager();
    _authManager.addAuthStateChangeFunction(this.onAuthChange);
    _dbHandler.getClientTypesDB(this.onDbHandlerFinish);
    const cookies = Cookies.get()
    return {
      dbHandler: _dbHandler,
      dbLoading: true,
      clientTypes: [],
      selectedMenu: (cookies["selectedMenu"] ? cookies["selectedMenu"] : ""),
      selectedClientType: (cookies["selectedClientType"] ? cookies["selectedClientType"] : ""),
      selectedClient: (cookies["selectedClient"] ? cookies["selectedClient"] : ""),
      user: null,
      authManager: _authManager,
      userObj: null,
      authInitDone: false,
      windowScrollLocked: false,
      showContactUs: false,
      showTermsAndConditions: false,
      showDeliveryModal: false,
      selectedTermsMenu: "",
    }
  },
  onAuthChange: function (user) {
    if (user) {
      this.state.authManager.getUserObjectFromDB(this.state.dbHandler,
        () => {
          this.setState({
            userObj: this.state.authManager.getUserObject(),
            user: user,
            authInitDone: true,
          })
        });
    }
    else {
      this.setState({
        userObj: null,
        user: null,
        authInitDone: true,
      });
    }
  },
  onDbHandlerFinish: function (clientTypes) {
    this.setState({
      dbLoading: false,
      clientTypes: clientTypes
    })
  },
  createContentByPath: function () {
    window.scrollTo(0, 0);
    switch (this.state.selectedMenu) {
      case "":
        return (
          <div className="app-container">
            <Header
              onClick={this.onMenuChange}
              authManager={this.state.authManager}
              dbHandler={this.state.dbHandler}
              user={this.state.userObj}
              authInitDone={this.state.authInitDone} />
            <PartnerMenu
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
              authManager={this.state.authManager}
            />
            <QuickPick
              clientTypes={this.state.clientTypes}
              dbLoading={this.state.dbLoading}
              onClick={this.onQuickPickChange}
              onNewDeliveryClick={() => { this.setState({ showDeliveryModal: true }); }}
            />
            <Presentation />
            <CallForOrder />
            <DeMangoInfo
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
              onTermsClick={this.showTermsAndConditions} />
            <Footer />
            {/* <TestingDiv
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
            /> */}
            <GoUpButton />
          </div>
        );
      case "how_to":
        return (
          <div className="app-container">
            <Header
              onClick={this.onMenuChange}
              authManager={this.state.authManager}
              dbHandler={this.state.dbHandler}
              user={this.state.userObj}
              authInitDone={this.state.authInitDone} />
            <PartnerMenu
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
            />
            <KoloradoPage />
            <GoUpButton />
          </div>
        );
      case "contact":
        return (
          <div className="app-container">
            <Header
              onClick={this.onMenuChange}
              authManager={this.state.authManager}
              dbHandler={this.state.dbHandler}
              user={this.state.userObj}
              authInitDone={this.state.authInitDone} />
            <PartnerMenu
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
            />
            <QuickPick
              clientTypes={this.state.clientTypes}
              dbLoading={this.state.dbLoading}
              onClick={this.onQuickPickChange}
              onNewDeliveryClick={() => { this.setState({ showDeliveryModal: true }); }}
            />
            <Presentation />
            <CallForOrder />
            <DeMangoInfo
              user={this.state.userObj}
              dbHandler={this.state.dbHandler} />
            <Footer />
            <TestingDiv
              user={this.state.userObj}
              dbHandler={this.state.dbHandler}
            />
            <GoUpButton />
          </div>
        );
      case "partners":
        if (this.state.selectedClient) {
          return (
            <div className="app-container">
              <div className="store-stand-fixed">
                <Header
                  onClick={this.onMenuChange}
                  authManager={this.state.authManager}
                  dbHandler={this.state.dbHandler}
                  user={this.state.userObj}
                  authInitDone={this.state.authInitDone} />
                <StoreStand
                  clientTypes={this.state.clientTypes}
                  selectedClientType={this.state.selectedClientType}
                  onTypeChange={this.onQuickPickChange}
                  dbHandler={this.state.dbHandler}
                  authManager={this.state.authManager}
                  client={this.state.selectedClient}
                  backFunction={this.backFunction}
                  user={this.state.userObj}
                />
              </div>
            </div>

          );
        }
        else {
          return (
            <div className="app-container">
              <div className="partners">
                <Header
                  onClick={this.onMenuChange}
                  authManager={this.state.authManager}
                  dbHandler={this.state.dbHandler}
                  user={this.state.userObj}
                  authInitDone={this.state.authInitDone} />
                <Partners
                  clientTypes={this.state.clientTypes}
                  selectedClientType={this.state.selectedClientType}
                  onTypeChange={this.onQuickPickChange}
                  onClientSelection={this.onClientSelection}
                  backFunction={this.backFunction}
                  dbHandler={this.state.dbHandler} />
                <GoUpButton />
              </div>
            </div>
          );
        }
      case "myProfile":
        return (
          <div className="app-container">
            <div className="app-content">
              <Header
                onClick={this.onMenuChange}
                authManager={this.state.authManager}
                dbHandler={this.state.dbHandler}
                user={this.state.userObj}
                authInitDone={this.state.authInitDone} st />
              <MyProfile
                authManager={this.state.authManager}
                authInitDone={this.state.authInitDone}
                dbHandler={this.state.dbHandler}
                onClientSelection={this.onClientSelection}
                backFunction={this.backFunction}
                user={this.state.userObj}
              />
              <GoUpButton />

            </div>
          </div>
        );
      default:
        return (<></>);
    }
  },
  backFunction: function () {
    switch (this.state.selectedMenu) {
      case "":
      case "how_to":
      case "contact":
      case "myProfile":
        this.setState({
          selectedMenu: "",
          selectedClientType: "",
          selectedClient: "",
        });
        break;
      case "partners":
        if (this.state.selectedClient) {
          this.setState({
            selectedClient: "",
          });
        }
        else {
          this.setState({
            selectedMenu: "",
            selectedClientType: "",
            selectedClient: "",
          });
        }
        break;
      default:
        this.setState({
          selectedMenu: "",
          selectedClientType: "",
          selectedClient: "",
        });
        break;
    }

  },
  onClientSelection: function (client) {
    this.setState({
      selectedMenu: "partners",
      selectedClient: client
    });
    this.updateSessionCookies("partners", this.state.selectedClientType, client.id);
  },
  onQuickPickChange: function (newType) {
    this.setState({
      selectedClientType: newType,
      selectedMenu: "partners",
      selectedClient: ""
    });
    this.updateSessionCookies("partners", newType, "")
  },
  onMenuChange: function (newSelectedMenu) {
    if (newSelectedMenu === "partners") {
      this.setState({
        selectedMenu: newSelectedMenu,
        selectedClient: ""
      });
      this.updateSessionCookies(newSelectedMenu, this.state.selectedClientType, "");
    }
    // This is bypass for contact page to be changed with modal.
    else if (newSelectedMenu === "contact") {
      this.setState({ showContactUs: true });
    }
    else {
      this.setState({
        selectedClientType: "",
        selectedClient: "",
        selectedMenu: newSelectedMenu
      });
      this.updateSessionCookies(newSelectedMenu, "", "");
    }
  },
  updateSessionCookies: function (selectedMenu, selectedClientType, selectedClientId) {
    Cookies.remove('selectedMenu', { expires: 1 / 48 })
    Cookies.remove('selectedClientType', { expires: 1 / 48 })
    Cookies.remove('selectedClient', { expires: 1 / 48 })
    Cookies.set("selectedMenu", selectedMenu, { expires: 1 / 48 });
    Cookies.set("selectedClientType", selectedClientType, { expires: 1 / 48 });
    Cookies.set("selectedClient", selectedClientId, { expires: 1 / 48 });
  },
  showTermsAndConditions: function (selectedTab) {
    this.setState({
      showTermsAndConditions: true,
      selectedTermsMenu: selectedTab,
    });
  },
  showMessages: function () {
    let messages = [];
    messages.push(<CookieMessage onClick={() => {
      this.showTermsAndConditions("privatePolicy");
    }} />)
    return messages;
  },
  render: function () {
    return (
      <>
        <AppVersionControl dbHandler={this.state.dbHandler} currentVersion={packageJason.buildDate} />
        {/* <CountDownModal dbHandler={this.state.dbHandler} /> */}
        <AppConnectionState />
        <NotificationManager
          dbHandler={this.state.dbHandler}
          user={this.state.userObj}
          //TODO: Move this to global and user settings
          enableNotifications={(this.state.userObj && this.state.userObj.settings.enableNotifications !== undefined) ?
            this.state.userObj.settings.enableNotifications : true}
          enableNotificationSound={this.state.userObj && this.state.userObj.settings.enableNotificationSound !== undefined ?
            this.state.userObj.settings.enableNotificationSound : true}
        />
        {this.state.showContactUs ? <ContactUs onClose={() => { this.setState({ showContactUs: false }) }} /> : null}
        {this.createContentByPath()}
        {this.state.showDeliveryModal ?
          <DeliveryModal
            authManager={this.state.authManager}
            onClose={() => { this.setState({ showDeliveryModal: false }); }}
            user={this.state.userObj}
            dbHandler={this.state.dbHandler}
            showIt={this.state.showDeliveryModal} // TODO: Connect this prop so logic on line 350 will not be needed.
          />
          : null}
        {this.showMessages()}
        {this.state.showTermsAndConditions ?
          <TermsConditions
            dbHandler={this.state.dbHandler}
            selectedMenu={this.state.selectedTermsMenu}
            onClose={() => { this.setState({ showTermsAndConditions: false, selectedTermsMenu: "", }); }}
          /> : null}
      </>
    );
  }
});

export default App;
