import Image from "./image";
import MenuSubitem from "./menuSubItem";

class MenuItem {
    private _id: string;
    private _index: number;
    private _showOnWebsite: boolean;
    private _name: string;
    private _description: string;
    private _img: Image;
    private _price: number;
    private _use_price: boolean;
    private _items: { [id: number]: MenuSubitem; };
    private _itemCount: number;
    private _isImgFromWeb: boolean;

    constructor();
    constructor(index: number, showOnWebsite: boolean, name: string, description: string, photo: string, price: number);
    constructor(index?: number, showOnWebsite?: boolean, name?: string, description?: string, photo?: string, price?: number) {
        this._index = index !== undefined ? index : 0;
        this._showOnWebsite = showOnWebsite !== undefined ? showOnWebsite : false;
        this._name = name !== undefined ? name : "";
        this._description = description !== undefined ? description : "";
        this._img = new Image();
        this._img.normal = photo !== undefined ? photo : "";
        this._price = price !== undefined ? price : 0;
        this._items = {};
        this._id = "";
        this._use_price = false;
        this._itemCount = 1;
        this._isImgFromWeb = false;
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get index(): number {
        return this._index;
    }
    set index(value: number) {
        this._index = value;
    }

    get showOnWebsite(): boolean {
        return this._showOnWebsite;
    }
    set showOnWebsite(value: boolean) {
        this._showOnWebsite = value;
    }

    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }
    set description(value: string) {
        this._description = value;
    }

    get img(): Image {
        return this._img;
    }
    set img(value: Image) {
        this._img = value;
    }

    get price(): number {
        return this._price;
    }
    set price(value: number) {
        this._price = value;
    }

    get use_price(): boolean {
        return this._use_price;
    }
    set use_price(value: boolean) {
        this._use_price = value;
    }

    get items(): { [id: number]: MenuSubitem; } {
        return this._items;
    }
    set items(value: { [id: number]: MenuSubitem; }) {
        this._items = value;
    }

    get itemCount(): number {
        return this._itemCount;
    }
    set itemCount(value: number) {
        this._itemCount = value;
    }

    get isImgFromWeb(): boolean {
        return this._isImgFromWeb;
    }
    set isImgFromWeb(value: boolean) {
        this._isImgFromWeb = value;
    }

    addItem(item: MenuSubitem) {
        this._items[item.index] = item;
    }

    addNewItem() {
        let index: number = Object.keys(this._items).length;
        let item: MenuSubitem = new MenuSubitem(index, false, "Novi element", 0, false);
        this._items[index] = item;
        return item;
    }

    /**
     * Generates all maps needed SubItem manipulation.
     * 
     * @return map that contains three maps. 
     * 1. group2subitem - subitems grouped by group name.
     * 2. group2maxSelection - max selection by group.
     * 3. group2priceTrigger - price trigger by group.
     */
    generateSubItemMaps() {
        //subitem group to subitem
        const returnMap: any = {};
        returnMap.group2subitem = {};
        returnMap.group2maxSelection = {};
        returnMap.group2priceTrigger = {};
        Object.values(this._items).forEach(subItem => {
            if (subItem.group && subItem.showOnWebsite) {
                if (!returnMap.group2subitem[subItem.group]) {
                    returnMap.group2subitem[subItem.group] = [];
                }
                returnMap.group2subitem[subItem.group].push(subItem);

                if (subItem.maxItemsFromGroup > 0) {
                    if (!returnMap.group2maxSelection[subItem.group]) {
                        returnMap.group2maxSelection[subItem.group] = 0;
                    }
                    if (returnMap.group2maxSelection[subItem.group] < subItem.maxItemsFromGroup) {
                        returnMap.group2maxSelection[subItem.group] = subItem.maxItemsFromGroup;
                    }
                }

                if (subItem.selectionPriceTrigger > 0) {
                    if (!returnMap.group2priceTrigger[subItem.group]) {
                        returnMap.group2priceTrigger[subItem.group] = 0;
                    }
                    if (returnMap.group2priceTrigger[subItem.group] < subItem.selectionPriceTrigger) {
                        returnMap.group2priceTrigger[subItem.group] = subItem.selectionPriceTrigger;
                    }
                }
            }
        });
        return returnMap;
    }

    getAllExcludedGroups(): string[] {
        let _subItemMap: { [key: string]: boolean } = {};
        Object.values(this._items).forEach(subItem => {
            if (subItem.exclusive && subItem.selected) {
                subItem.excludedGroups.forEach(group => {
                    _subItemMap[group] = true;
                });
            }
        });
        return Object.keys(_subItemMap);
    }

    unselectAllItems(subitemMap: { [key: string]: Array<MenuSubitem> },
        group2priceTrigger: { [key: string]: Number }) {
        Object.values(subitemMap).forEach((subitemArray) => {
            subitemArray.forEach((subItem, index) => {
                if (subItem.isRadioButton && index === 0) {
                    subItem.selected = true;
                }
                else {
                    subItem.selected = false;
                    if (group2priceTrigger[subItem.group]) {
                        subItem.usePrice = false;
                    }
                }
            });
        });
    }

    removeItem(item: MenuSubitem) {
        let index_to_remove = item.index;
        for (let index = 0; index < Object.keys(this._items).length; index++) {
            if (index > index_to_remove) {
                this._items[index - 1] = this._items[index];
                this._items[index - 1].index = index - 1;
            }
        }
        delete this._items[Object.keys(this._items).length - 1];
    }

    moveUp(index_to_move: number) {
        if (index_to_move === 0) {
            return;
        }
        console.log("DEBUG!");
        console.log(this.items);

        let upper_element = this._items[index_to_move - 1];
        let selected_element = this._items[index_to_move];
        console.log(upper_element);
        console.log(selected_element);


        this._items[index_to_move] = upper_element;
        this._items[index_to_move - 1] = selected_element;
        this._items[index_to_move].index = index_to_move;
        this._items[index_to_move - 1].index = index_to_move - 1;
    }

    moveDown(index_to_move: number) {
        if ((Object.keys(this._items).length - 1) === index_to_move) {
            return;
        }
        let down_element = this._items[index_to_move + 1];
        let selected_element = this._items[index_to_move];
        this._items[index_to_move] = down_element;
        this._items[index_to_move + 1] = selected_element;
        this._items[index_to_move].index = index_to_move;
        this._items[index_to_move + 1].index = index_to_move + 1;
    }

    getPriceFromSubItems() {
        let price = 0;
        Object.values(this._items).forEach(subItem => {
            if (subItem.usePrice && subItem.isRadioButton && price === 0) {
                price = subItem.price;
            }
        });
        return price;
    }

    getCurrentPrice(additionalItems: any[]) {
        let price: number = 0;
        Object.values(this.items).forEach((item) => {
            if (item.selected && item.usePrice) {
                price = Number(price) + Number(item.price)
            }
        });
        if (additionalItems && additionalItems.length > 0) {
            additionalItems.forEach((item: any) => {
                if (item.selected && item.use_price) {
                    price = Number(price) + Number(item.price)
                }
            });
        }

        return ((price + Number(this.price)) * this._itemCount).toFixed(2);
    }

    toJson() {
        let items_json: Array<{}> = [];
        Object.values(this._items).forEach((item: MenuSubitem) => {
            items_json.push(item.toJson());
        });
        return {
            id: this._id,
            index: this._index,
            showOnWebsite: this._showOnWebsite,
            name: this._name,
            description: this._description,
            img: this._img.toJason(),
            price: this._price,
            use_price: this._use_price,
            items: items_json,
            itemCount: this._itemCount, // itemCount must be skipped in admin
            isImgFromWeb: this._isImgFromWeb,
        }
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.index) {
                this.index = jsonObject.index;
            }
            if (jsonObject.showOnWebsite) {
                this._showOnWebsite = jsonObject.showOnWebsite;
            }
            if (jsonObject.name) {
                this._name = jsonObject.name;
            }
            if (jsonObject.description) {
                this._description = jsonObject.description;
            }
            if (jsonObject.price) {
                this._price = jsonObject.price;
            }
            if (jsonObject.use_price) {
                this._use_price = jsonObject.use_price;
            }
            if (jsonObject.img) {
                let img = new Image();
                img.loadFromJson(jsonObject.img)
                this._img = img;
            }
            if (jsonObject.items) {
                this._items = {};
                Object.values(jsonObject.items).forEach((itemJson: any) => {
                    const itemObj = new MenuSubitem();
                    itemObj.loadFromJson(itemJson);
                    this.addItem(itemObj);
                });
            }
            if (jsonObject.itemCount) {
                this._itemCount = jsonObject.itemCount;
            }
            if (jsonObject.isImgFromWeb) {
                this._isImgFromWeb = jsonObject.isImgFromWeb;
            }
        }
    }
}

export default MenuItem