/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import Address from "./address";
import WorkingTime from "./workingTime";
import Menu from "./menu";
import Image from "./image";
import enumOrderPayer from "./enums/orderPayer";
import enumWorkingState from "./enums/workingState";
/**
 * Placeholder class for Address attributes.
 */
export class Client {
  id: string;
  index: number;
  showOnWebsite: boolean;
  name: string;
  type: string;
  types: {};
  locations: Address[];
  img: Image;
  headerImg: Image;
  sideMenuImg: Image;
  workingTime: WorkingTime[];
  menus: { [id: number]: Menu; };
  enablePromotion: boolean;
  info: string;
  defaultOrderTime: number;
  defaultDeliveryTime: number;
  informMeByPhone: boolean;
  isOverloaded: boolean;
  packingFee: number;
  orderPayer: enumOrderPayer;
  minimumDeliveryCost: number;
  deliveryId: string;
  workingState: enumWorkingState;

  constructor() {
    this.id = "";
    this.index = 0;
    this.showOnWebsite = false;
    this.name = "";
    this.type = "";
    this.types = {};
    this.locations = [];
    this.img = new Image();
    this.headerImg = new Image();
    this.sideMenuImg = new Image();
    this.workingTime = [];
    this.menus = {};
    this.enablePromotion = false;
    this.info = "";
    this.defaultDeliveryTime = 0;
    this.defaultOrderTime = 0;
    this.informMeByPhone = false;
    this.isOverloaded = false;
    this.packingFee = 0;
    this.orderPayer = enumOrderPayer.USER;
    this.minimumDeliveryCost = 0;
    this.deliveryId = "";
    this.workingState = enumWorkingState.WORKING;
  }

  isWorking() {
    let isWorking = false;
    this.getWorkingTimesForCurrentDay().forEach(wt => {
      if (wt && wt.isCurrentlyWorking()) {
        isWorking = true;
      }
    });
    return isWorking;
  }

  // This function is moved to server side, but it needs to stay here because,
  // server will run it initially on collection of clients, but it needs to run here
  // when client is updated in store stand.
  getWorkingState(): enumWorkingState {
    const workingTimes = this.getWorkingTimesForCurrentDay();
    let workingState: enumWorkingState = enumWorkingState.CLOSED;

    if (workingTimes.length > 1) {
      const dateObj = new Date();
      const currentHours: Number = dateObj.getHours();
      const currentMinutes: Number = dateObj.getMinutes();
      const activeWT: WorkingTime[] = [];
      const beforeWT: WorkingTime[] = []; // Current time is before WT
      const afterWT: WorkingTime[] = []; // Current time is after WT

      workingTimes.forEach((wt: WorkingTime) => {
        if (currentHours > Number(wt.delivery_start_hours) &&
          currentHours < Number(wt.delivery_end_hours)) {
          activeWT.push(wt);
        }
        else if (currentHours < Number(wt.delivery_start_hours)) {
          beforeWT.push(wt);
        }
        else if (currentHours > Number(wt.delivery_end_hours)) {
          afterWT.push(wt);
        }
        if (Number(wt.delivery_start_hours) > Number(wt.delivery_end_hours)) {
          if (currentHours > Number(wt.delivery_start_hours) ||
            currentHours < Number(wt.delivery_end_hours)) {
            activeWT.push(wt);
          }
          else if (currentHours < Number(wt.delivery_start_hours)) {
            beforeWT.push(wt);
          }
          else if (currentHours > Number(wt.delivery_end_hours)) {
            afterWT.push(wt);
          }
        }
        if (currentHours === Number(wt.delivery_end_hours)) {
          if (currentMinutes <= Number(wt.delivery_end_minutes)) {
            activeWT.push(wt);
          }
          else {
            afterWT.push(wt);
          }
        }
        if (currentHours === Number(wt.delivery_start_hours)) {
          if (currentMinutes >= Number(wt.delivery_start_minutes)) {
            activeWT.push(wt);
          }
          else {
            beforeWT.push(wt);
          }
        }
      });

      if (activeWT.length > 0) {
        workingState = enumWorkingState.WORKING;
      }
      else if (beforeWT.length !== workingTimes.length || afterWT.length !== workingTimes.length) {
        workingState = enumWorkingState.BREAK;
      }
      else {
        workingState = enumWorkingState.CLOSED;
      }

    }
    else {
      workingState = (workingTimes[0] && workingTimes[0].isCurrentlyWorking()) ? enumWorkingState.WORKING : enumWorkingState.CLOSED;
    }
    return workingState;
  }

  getWorkingTimesForCurrentDay(): WorkingTime[] {
    let dayNumber = this.getCurrentDayIndex();
    let returnWorkingTimes: WorkingTime[] = [];
    this.workingTime.forEach(wTimeObj => {
      if (wTimeObj.applied_days[dayNumber]) {
        returnWorkingTimes.push(wTimeObj);
      }
    });
    return returnWorkingTimes;
  }

  getCurrentActiveWorkingTime(): WorkingTime | undefined {
    let wtToReturn: WorkingTime | undefined = undefined;
    this.getWorkingTimesForCurrentDay().forEach((wt: WorkingTime) => {
      if (wt.isCurrentlyWorking()) {
        wtToReturn = wt;
      }
    });
    return wtToReturn;
  }

  getCurrentDayIndex() {
    let currentDate = new Date();
    let dayNumber = currentDate.getDay() - 1;
    // converting from first Sunday to first Monday!
    if (dayNumber < 0) {
      dayNumber = 6
    }
    return dayNumber;
  }

  isEmpty() {
    return (!(this.name && this.menus))
  }

  addMenu(item: Menu) {
    this.menus[item.index] = item;
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.id) {
        this.id = jsonObject.id;
      }
      if (jsonObject.index) {
        this.index = jsonObject.index;
      }
      if (jsonObject.showOnWebsite) {
        this.showOnWebsite = jsonObject.showOnWebsite;
      }
      if (jsonObject.name) {
        this.name = jsonObject.name;
      }
      if (jsonObject.type) {
        this.type = jsonObject.type;
      }
      if (jsonObject.types) {
        this.types = jsonObject.types;
      }
      if (jsonObject.locations) {
        this.locations = [];
        jsonObject.locations.forEach((addressJSON: {}) => {
          const address = new Address();
          address.loadFromJson(addressJSON);
          this.locations.push(address);
        });
      }
      if (jsonObject.img) {
        let img = new Image();
        img.loadFromJson(jsonObject.img);
        this.img = img;
      }
      if (jsonObject.headerImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.sideMenuImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.working_time) {
        this.workingTime = [];
        jsonObject.working_time.forEach((wTimeJSON: {}) => {
          const wTime = new WorkingTime();
          wTime.loadFromJson(wTimeJSON);
          this.workingTime.push(wTime);
        });
      }
      if (jsonObject.enable_promotion) {
        this.enablePromotion = jsonObject.enable_promotion;
      }
      if (jsonObject.menus) {
        // this.menus = jsonObject.menus;
      }
      if (jsonObject.info) {
        this.info = jsonObject.info;
      }
      if (jsonObject.defaultOrderTime) {
        this.defaultOrderTime = jsonObject.defaultOrderTime;
      }
      if (jsonObject.defaultDeliveryTime) {
        this.defaultDeliveryTime = jsonObject.defaultDeliveryTime;
      }
      if (jsonObject.informMeByPhone) {
        this.informMeByPhone = jsonObject.informMeByPhone;
      }
      if (jsonObject.isOverloaded) {
        this.isOverloaded = jsonObject.isOverloaded;
      }
      if (jsonObject.packingFee) {
        this.packingFee = jsonObject.packingFee;
      }
      if (jsonObject.orderPayer) {
        this.orderPayer = jsonObject.orderPayer;
      }
      if (jsonObject.minimumDeliveryCost) {
        this.minimumDeliveryCost = jsonObject.minimumDeliveryCost;
      }
      if (jsonObject.deliveryId) {
        this.deliveryId = jsonObject.deliveryId;
      }
      if (typeof jsonObject.workingState !== "undefined") {
        this.workingState = jsonObject.workingState;
      }
    }
  }

  toJason(): any {
    const locationsJson: Array<{}> = [];
    this.locations.forEach((location) => {
      locationsJson.push(location.toJason());
    });
    const wTimesJSON: Array<{}> = [];
    this.workingTime.forEach((wTime) => {
      wTimesJSON.push(wTime.toJason());
    });
    return {
      id: this.id,
      index: this.index,
      showOnWebsite: this.showOnWebsite,
      name: this.name,
      type: this.type,
      types: this.types,
      locations: locationsJson,
      img: this.img.toJason(),
      headerImg: this.headerImg.toJason(),
      sideMenuImg: this.sideMenuImg.toJason(),
      working_time: wTimesJSON,
      enable_promotion: this.enablePromotion,
      info: this.info,
      defaultDeliveryTime: this.defaultDeliveryTime,
      defaultOrderTime: this.defaultOrderTime,
      informMeByPhone: this.informMeByPhone,
      isOverloaded: this.isOverloaded,
      packingFee: this.packingFee,
      orderPayer: this.orderPayer,
      minimumDeliveryCost: this.minimumDeliveryCost,
      deliveryId: this.deliveryId,
      // menus: this.menus,
    };
  }
}

export default Client;
