import '../styles/KoloradoPage.css';
var createReactClass = require('create-react-class');


var KoloradoPage = createReactClass({
    render: function () {
        return (
            <div className="kolorado-page-container">
                <div className="price-container-big">
                    <img src="./images/regular/cenovnik.svg" alt="Kolorado cenovnik" />
                </div>
                <div className="price-container-small">
                    <img src="./images/regular/mapacenovnika.svg" alt="Kolorado cenovnik" />
                    <img src="./images/regular/listacenovnik.svg" alt="Kolorado cenovnik" />
                </div>
                <div className="main-content">
                    <div className="de-mango-top">
                        <h1>Kolorado <span>021</span> dostava</h1>
                    </div>
                    <div className="phone-div">
                        <div className="first-div">
                            <h2>065/574-1592</h2>
                            <h2>065/574-1593</h2>
                        </div>
                        <div className="middle-div">
                            <img src="./images/callagent.svg" alt="Kolorado 021 dispecer" />
                        </div>
                        <div className="third-div">
                            <h2>065/574-1592 <img src="./images/viber.svg" alt="Viber ikonica" /></h2>
                            <h2>065/4063662 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            <h2 className="hidden-third-div-element">065/574-1592</h2>
                            <h2 className="hidden-third-div-element">065/574-1593</h2>
                        </div>
                    </div>

                    <div className="de-mango-bottom">
                        <p>
                            Bilo da želite da se informišete ili da zakažete dostavu,
                            uvek nas možete kontaktirati na neki od gore navedenih telefona.
                            Za nas ne postoji radno vreme, tu smo za sva vaša pitanja i porudžbine u bilo koje doba dana  ili noći!
                        </p>
                    </div>
                </div>

                <div className="kolorado-delivery-types">
                    <div className="single-delivery-type">
                        <img src="images/small_img/delivery-yellow.svg" alt="Expres dostava ikonica" />
                        <p>Brza dostava</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/fast-food-yellow.svg" alt="Dostava hrane iz vašeg omiljenog restorana ikonica" />
                        <p>Dostava hrane iz vašeg omiljenog restorana</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/medicine.svg" alt="Dostava lekova ikonica" />
                        <p>Dostava lekova na slobodnoj prodaji kao i na recept</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/skin-care.svg" alt="Dostava kozmetike ikonica" />
                        <p>Dostava kozmetike</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/repair.svg" alt="Dostava auto delova ikonica" />
                        <p>Dostava auto delova</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/pet-food.svg" alt="Dostava hrane za ljubimce ikonica" />
                        <p>Dostava hrane za Vaše ljubimce</p>
                    </div>
                    <div className="single-delivery-type">
                        <img src="images/small_img/document.svg" alt="Dostava važnih dokumenata ikonica" />
                        <p>Dostava važnih dokumenata</p>
                    </div>

                </div>

                <div className="kolorado-mango-div">
                    <div className="single-mango">
                        <img src="images/regular/mango1.svg" alt="Dostava u roku od 1h" />
                        <p>Dostava u roku od 1h</p>

                    </div>
                    <div className="single-mango">
                        <img src="images/regular/mango2.svg" alt="Na vašoj adresi u par klikova" />
                        <p>Na vašoj adresi u par klikova</p>

                    </div>
                    <div className="single-mango">
                        <img src="images/regular/mango3.svg" alt="ogućnost poručivanja 24/7" />
                        <p>Mogućnost poručivanja 24/7</p>

                    </div>
                    <div className="single-mango">
                        <img src="images/regular/mango5.svg" alt="Preko 20 kurira" />
                        <p>Preko 20 kurira</p>

                    </div>

                </div>
                <div className="social-media-container">
                    <p>Zapratite nas</p>
                    <div className="social-media-icons">
                        <a href="https://www.instagram.com/_de_mango/">
                            <img src="images/small_img/instagram.svg" alt="" />
                        </a>
                        <a href="https://www.facebook.com/deMango.novisad">
                            <img src="images/small_img/facebook.svg" alt="" />
                        </a>

                    </div>

                </div>
            </div>
        )
    }
});

export default KoloradoPage
