import React from 'react';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from "../model/user";
import '../styles/LoginModal.css';
import MessageBox from "../common/MessageBox";
import Loader from "./Loader";
import { disableScroll, enableScroll } from '../common/effectsHelper';
var createReactClass = require('create-react-class');

const modalTheme = createTheme({
    palette: {
        primary: {
            light: 'white',
            main: 'rgb(247,182,20)',
            dark: 'rgb(67, 70, 78)',
            contrastText: '#ffffff'
        },

        secondary: {
            light: 'white',
            main: 'rgb(247, 20, 20)',
            dark: 'rgb(67, 70, 78)',
            contrastText: '#ffffff'
        }
    }
});

var LoginModal = createReactClass({
    getInitialState: function () {
        return {
            isOpen: true,
            selectedContent: "loginForm",
            authManager: this.props.authManager,
            phone: "+381",
            code: "",
            name: "",
            errorMessage: "",
            reCaptchaSuccess: false,
            user: new User(),
            agreedWithDevil: false,
            showTermsAndConditions: false,
            updateUser: false,
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    changeSelectedContent: function () {
        switch (this.state.selectedContent) {
            case "loginForm":
                if (this.validateNumber()) {
                    this.props.authManager.installInvisibleReCaptcha("recap-container", this.onReCaptchaDone);
                    this.state.authManager.sendSMStoPhone(this.state.phone, window.recaptchaVerifier, this.onCodeSendToUser);
                    this.props.dbHandler.getUserByIdDB(this.state.phone, this.onGetUserDone);
                    this.setState({ selectedContent: "waitingForUserDB" });
                }
                else {
                    //TODO: Wrong number action
                }
                break;
            case "confirmationForm":
                if (this.validateNameAndCode() && this.state.reCaptchaSuccess) {
                    this.state.authManager.sendVerificationCode(this.state.code, this.onCodeSendToDB);
                    this.setState({ selectedContent: "waitingForLoginDB" });
                }
                else {
                    // TODO: Wrong Name or Code action
                }
                break;
            case "termAndConditions":
                if (this.state.agreedWithDevil) {
                    const userObj = this.state.authManager.getUserObject();
                    userObj.agreedWithTermsAndConditions = true;
                    if (this.state.updateUser) {
                        this.props.dbHandler.updateUserDB(userObj, this.handleClose);
                    }
                    else {
                        this.props.dbHandler.addUserDB(userObj, this.handleClose);
                    }
                    this.setState({ selectedContent: "waitingForClose" });
                }
                else {

                }
                break;
            default:
                break;
        }
    },
    onGetUserDone: function (user) {
        let updateUser = false;
        if (user) {
            updateUser = true;
            this.state.authManager.setUserObject(user);
        }
        this.setState({
            user: user,
            selectedContent: "confirmationForm",
            updateUser: updateUser,
        });
    },
    onCodeSendToUser: function (success, errorMessage) {
        if (success) {
            this.setState({ codeSentToUser: true });
        }
        else {
            this.setState({ errorMessage: errorMessage });
        }
    },
    onCodeSendToDB: function (userFirebase, errorMessage) {
        if (userFirebase) {
            let userObj = this.props.authManager.getUserObject();
            // If it's first login.
            const name = this.state.name ? this.state.name : (userFirebase.displayName ? userFirebase.displayName : "Korisnik")
            if (name !== userFirebase.displayName) {
                this.state.authManager.updateUserName(userFirebase, name);
            }
            if (!userObj || !userObj.phone) {
                userObj = new User();
                userObj.name = name;
                userObj.phone = this.state.phone;
            }
            else if (userObj) {
                if (!userObj.phone) {
                    userObj.phone = this.state.phone;
                }
                if (!userObj.name || userObj.name !== name) {
                    userObj.name = name;
                }
            }
            this.props.authManager.setUserObject(userObj);

            if (userObj.agreedWithTermsAndConditions) {
                if (this.state.updateUser) {
                    this.props.dbHandler.updateUserDB(userObj, this.handleClose);
                }
                else {
                    this.props.dbHandler.addUserDB(userObj, this.handleClose);
                }
            }
            else {
                this.setState({ selectedContent: "termAndConditions" });
            }
        }
        else {
            this.setState({ errorMessage: errorMessage });
        }
    },
    onInputChange: function (event) {
        this.setState({ [event.target.name]: event.target.value });
    },
    validateNumber: function () {
        return true;
    },
    validateNameAndCode: function () {
        return true;
    },
    onReCaptchaDone: function () {
        this.setState({ reCaptchaSuccess: true });
    },
    onKeyPress: function (event) {
        if (event.key === "Enter") {
            this.changeSelectedContent();
        }
    },
    handleClose: function () {
        this.setState({ isOpen: false });
        const userObject = this.props.authManager.getUserObject();
        if (!userObject || !userObject.agreedWithTermsAndConditions) {
            this.props.authManager.logOut();
        }
        this.props.onClose();
    },
    render: function () {
        let content;
        let buttonText;
        if (this.state.selectedContent === "waitingForUserDB" ||
            this.state.selectedContent === "waitingForLoginDB" ||
            this.state.selectedContent === "waitingForClose") {
            content = <Loader />;
        }
        else if (this.state.showTermsAndConditions) {
            return (
                <MessageBox
                    title="Terms And Conditions"
                    secondContent=
                    {<div className="termsandcondition">
                        <h2 >Uslovi korišćenja web servisa „demango.rs“</h2><br />

                        <p><strong>Poštovani korisnici</strong></p><br />

                        <p>Svaka poseta našem sajtu, narudžbe, kupovina, ostavljanje utisaka i slično, znači da ste ove Uslove korišćenja (u tekstu „Uslovi“) pročitali i da se sa njima u potpunosti slažete.

                            Ukoliko su vam ovi Uslovi korišćenja neprihvatljivi, molimo vas da odustanete od dalje upotrebe ovog sajta.</p><br />
                        <p><strong>Opšte odredbe</strong></p><br />
                        <p>Korišćenje usluga i sadržaja sa sajta demango.rs, smatra se dokazom da ste upoznati sa Uslovima korišćenja objavljenim na ovoj stranici sajta.</p><br />

                        <p><strong>Korišćenje sajta i sadržaja na njemu</strong></p><br />
                        <p>Ovaj sajt i njegov sadržaj možete koristiti samo za ličnu upotrebu i na sopstvenu odgovornost. Nije dozvoljeno da sadržaj sajta koristite u javne i komercijalne svrhe.
                            Materijali na ovom sajtu su naše vlasništvo zaštićeno autorskim pravima.
                            Neovlašćena upotreba sadržaja sa ovog sajta predstavlja kršenje Zakona o autorskom i srodnim pravima i drugih zakona i propisima koji regulišu ovu oblasti.
                            Sadržaj sa sajta možete preuzimati isključivo za informativne i edukativne svrhe, uz obavezu da oznake autorstva sa originala zadržite na kopijama.
                            Ukoliko želite sadržaje ovog sajta da koristite u druge svrhe koje ovde nisu odobrene, potrebno je da nas kontaktirate u cilju dobijanja dozvole za to.
                            Zabranjeno je na ovaj sajt postavljati protivzakonite i neistinite sadržaje i komentare.</p><br />
                        <p><strong>Zaštita podataka</strong></p><br />
                        <p>Lične podatke koje kao posetioci i korisnici ovog sajta ostavite na sajtu radi dobijanja bilo koje vrste našeg proizvoda ili usluge, zaštićeni su u skladu sa Zakonom o zaštiti podataka o ličnosti i našom Politikom privatnosti.

                            Vaše lične podatke koristimo isključivo za obradu vaših zahteva i komunikaciju sa vama. Informacije, podaci i drugo što ostavljate na ovom sajtu, a nisu vaši lični podaci, ne smatramo poverljivim materijalom i koristimo ih u skladu sa uobičajenom poslovnom politikom.</p><br />

                        <p><strong>Linkovi ka drugim web sajtovima</strong></p><br />
                        <p>Linkovi (veze) na našem sajtu sa drugim web lokacijama, služe isključivo za vaše dodatno informisanje. Mi ni u kom vidu nismo odgovorni za te web lokacije niti za sadržaje na njima.</p><br />

                        <p><strong>Ograničene garancije</strong></p><br />
                        <p>Nastojimo da sve prikazano na ovom sajtu bude tačno, da imamo ispravne nazive, specifikacije, fotografije, cene itd.

                            I pored toga, ne garantujemo apsolutnu tačnost informacija i podataka, primenjivost i kompletnost sadržaja ili apsolutno odsustvo grešaka na ovom sajtu. Moguća su i neka odstupanja od ovih Uslova u zakonski dozvoljenim granicama.</p><br />

                        <p><strong>Ograničenje odgovornosti</strong></p><br />
                        <p>Ne prihvatamo odgovornost za bilo kakvu štetu ili troškove koji mogu nastati kao posledica upotrebe ili zloupotrebe ovog sajta i sadržaja preuzetog sa njega.</p><br />

                        <p><strong>Zaštitni znaci</strong></p><br />
                        <p>Logotipi, zaštitni znaci, nazivi, oznake i slično, koji se pojavljuju na ovom sajtu, zaštitni su znaci odgovarajućih subjekata i ne mogu se ni na koji način koristiti bez saglasnosti vlasnika.</p><br />

                        <p><strong>Upotreba kolačića</strong></p><br />
                        <p>Stranice ovog sajta prikupljaju određene informacija putem kolačića (cookies) poslatih na vaš IT uređaj. Kolačići vama omogućavaju bolje korisničko ikustvo, a nama obezbeđuju informacije o kvalitetu poseta i broju posetilaca.</p><br />

                        <p>Kolačiči ne prikupljaju lične podatke i informacije o pojedincima. Svi uzeti podaci su analitičke i zbirne prirode.</p><br />

                        <p><strong>Cene i cenovna politika</strong></p><br />
                        <p>Cenovna politika je objašnjena na odgovarajućim stranicam ovog sajta. Cene su iskazane u dinarima. PDV je uračunat u cenu.</p><br />

                        <p><strong>Završne odredbe</strong></p><br />
                        <p>Upotrebom ovog sajta potvrđujete da ste pročitali, razumeli i prihvatili ove Uslove korišćenja i da ćete ih poštovati.
                            Mi zadržavamo pravo da ove Uslove ažuriramo ili promenimo bez prethodnog upozorenja.
                            Sve promene odredbi ovih Uslova biti će prikazane na ovoj stranici sajta „demango.rs“.
                            U vašem je interesu da povremeno proverite da li su se Uslovi promenili.
                            Pojedine odredbe ovih Uslova korišćenja mogu se podrediti izričito naglašenim uslovima i istaknutim obaveštenjima datim na nekim drugim mestima na ovom sajtu.</p><br />
                        <p><strong>Sankcije za kršenje ovih odredbi</strong></p><br />
                        <p>U slučaju kršenja odredbi ovih Uslova korišćenja, možemo poslati upozorenje, privremeno ili za stalno blokirati pristup sajtu, podneti prijavu nadležnim organima ili podneti tužbu.</p><br />

                        <p>Ovi Uslovi korišćenja i navedene odredbe se tumače u skladu sa zakonima Republike Srbije.</p><br />
                    </div>}
                    onClick={() => { this.setState({ showTermsAndConditions: false }); }} />
            );
        }
        else if (this.state.selectedContent === "loginForm") {
            content = <div className="login-modal-content">
                <p>Unesite broj telefona na koji će biti poslat broj za prijavu.</p>
                <ThemeProvider theme={modalTheme}>
                    <form action="" id="login-form" className="phone-login-form">
                        <TextField name="phone" id="outlined-basic" label="Broj telefona" type="tel" variant="outlined" color="primary" fullWidth="true" placeholder="npr. +381652855055"
                            onChange={this.onInputChange} value={this.state.phone} onKeyPress={this.onKeyPress} />
                    </form>
                </ThemeProvider>
            </div>
            buttonText = "Pošalji kod";
        }
        else if (this.state.selectedContent === "confirmationForm") {
            content = <div className="login-modal-confirmation">
                {(this.state.user && this.state.user.name) ?
                    <>
                        {/* Maybe show user name and say "Welcome". */}
                    </> :
                    <>
                        <p>Vaše ime:</p>
                        <ThemeProvider theme={modalTheme}>
                            <form action="" id="code-login-form" >
                                <TextField name="name" id="outlined-basic" label="Ime" variant="outlined" color="primary" fullWidth="true"
                                    onChange={this.onInputChange}
                                    value={this.state.name}
                                    onKeyPress={this.onKeyPress} />
                            </form>
                        </ThemeProvider>
                    </>}
                <p>Unesite kod za prijavu koji ste dobili u poruci.</p>
                <ThemeProvider theme={modalTheme}>
                    <form action="" id="code-login-form" >
                        <TextField name="code" type="number" id="outlined-basic" label="Kod za prijavu" variant="outlined" color="primary" fullWidth="true"
                            onChange={this.onInputChange}
                            value={this.state.code}
                            onKeyPress={this.onKeyPress} />
                    </form>
                </ThemeProvider>
            </div>
            buttonText = "Uloguj se";
        }
        else if (this.state.selectedContent === "termAndConditions") {
            content = <div className="login-modal-terms">
                <input type="checkbox" id="terms" onChange={(event) => { this.setState({ agreedWithDevil: event.target.checked }) }} />
                <label htmlFor="terms" className="input-black-box"> <i><FontAwesomeIcon icon={faCheck} /></i></label>
                <p>Pročitao sam i slažem se sa <a href onClick={() => { this.setState({ showTermsAndConditions: true }); }}>uslovima korišćenja</a>.</p>
            </div>
            buttonText = "Uloguj se";
        }

        if (this.state.errorMessage) {
            return (
                <MessageBox title="Greška!" content={this.state.errorMessage.message} onClick={() => { this.handleClose(); }} />
            );
        }
        else {
            return (
                <div className="login-modal-container" >
                    <div className="login-modal">
                        <div id="recap-container"></div>
                        <div className="login-modal-header">
                            <h2>Prijava</h2>
                            <span className="close-modal" onClick={this.handleClose}>
                                <h1>&times;</h1>
                            </span>
                        </div>
                        <div className="login-modal-content">
                            {content}
                        </div>
                        <div className="login-modal-footer">
                            {(this.state.selectedContent !== "waitingForUserDB" && this.state.selectedContent !== "waitingForLoginDB") ?
                                <button onClick={this.changeSelectedContent} className="send-request" >
                                    {buttonText}
                                </button> : null}
                        </div>
                    </div>
                </div>
            );
        }
    }
});

export default LoginModal
