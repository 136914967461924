import '../styles/Footer.css';
var createReactClass = require('create-react-class');

var Footer = createReactClass({
    render: function () {
        return (
            <>
                <footer className="site-footer">
                    <p>{(new Date().getFullYear())}. © de</p><p className="mango">Mango</p><p>. Sva prava zadržana.&nbsp;&nbsp; </p>
                </footer>
            </>
        )
    }
})


export default Footer