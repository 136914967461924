import '../styles/Success.css';

function NoData() {
    return (
        <div className="error-meesage">
            <img src="images/small_img/cancel.svg" alt="Nema podataka" />
            <h3>Nema podataka!</h3>
        </div>
    )
}

export default NoData
