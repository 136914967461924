import { faHistory, faInfo, faMapMarkerAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Background from "../background/myprofile.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/AsideMenu.css';
import '../styles/MainStyle.css';
import { disableScroll, enableScroll } from '../common/effectsHelper';
import LoaderSmall from '../common/LoaderSmall';
var createReactClass = require('create-react-class');
const defaultImg = new Image();
defaultImg.small = "images/small_img/cutlery.png";
defaultImg.alt = "Ikonica za vrstu lokala.";

var AsideMenu = createReactClass({
    getInitialState: function () {
        return this.getInitObject();
    },
    getInitObject: function () {
        const retObj = {};
        if (this.props.client) {
            retObj.client = this.props.client;
            retObj.selectedMenu = Object.values(this.props.client.menus).length > 1 ? "all" :
                (Object.values(this.props.client.menus)[0] ? Object.values(this.props.client.menus)[0].index : "all");
            retObj.listClient = true;
            retObj.menusCount = Object.values(this.props.client.menus).length;
        }
        if (this.props.clientTypes) {
            retObj.clientTypes = this.props.clientTypes;
            retObj.selectedClientType = this.props.selectedClientType;
            retObj.showAsideMenuChecked = false;
        }
        if (this.props.user) {
            retObj.user = this.props.user;
            retObj.selectedMenu = this.props.selectedMenu;
            retObj.listMyProfileItems = true;
        }
        retObj.loading = this.props.loading;
        return retObj;
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.clientTypes !== prevProps.clientTypes ||
            this.props.client !== prevProps.client ||
            this.props.user !== prevProps.user ||
            this.props.loading !== prevProps.loading ||
            (this.props.client && this.state.menusCount !== Object.values(this.props.client.menus).length)) {
            this.setState(this.getInitObject());
        }
    },
    onTypeChange: function (id) {
        if (id) {
            this.toggleWindowScroll(false);
            this.setState({
                selectedClientType: id,
                showAsideMenuChecked: false,
            });
            if (this.props.onTypeClick) {
                this.props.onTypeClick(id);
            }
            else {
                console.log("On side Type item change function is not set!");
            }
        }
        else {
            console.log("Element ID is null!");
        }
    },
    onMenuChange: function (id) {
        this.setState({ selectedMenu: id });
        if (this.props.onMenuClick) {
            this.props.onMenuClick(id);
        }
        else {
            console.log("On side menu item change function is not set!");
        }
    },
    onCheckBoxChange: function (event) {
        switch (event.target.id) {
            case "check-filter":
                this.setState({ showAsideMenuChecked: event.target.checked });
                this.toggleWindowScroll(event.target.checked);
                break;
            default:
                break;
        }
    },
    toggleWindowScroll: function (toggleOff) {
        if (toggleOff) {
            disableScroll();
        }
        else {
            enableScroll();
        }
    },
    // TODO: List of items could be a property and it can be displayed with map function. Not hardcoded. 
    // Than, I will not have 3 different types of aside menu. It will be only one.
    render: function () {
        if (this.state.listMyProfileItems) {
            return (
                // for future background image: style = {{backgroundImage: `url(${Background})`}}
                <div className="aside-menu-container">
                    <div className="aside-menu-food-type">
                        <div className={(this.state.loading ? "show-on-load" : "hide-after-load")}>
                            <LoaderSmall />
                        </div>
                        <h3 className="aside-menu-user-name">{this.state.user.name}</h3>
                        <div className={(this.state.selectedMenu === "info" ? "selected" : "menu-filter-container")} id="info" onClick={this.onMenuChange.bind(this, "info")}>
                            <p id="info">Osnovni podaci</p>
                            <i className="flat-icon" id="info">
                                <img id="info" src="images/small_img/info.png" alt="Ikonica za karticu moji podaci" />
                            </i>
                            <i className="font-awesome" id="info">
                                <FontAwesomeIcon icon={faInfo} />
                            </i>
                        </div>
                        <div className={(this.state.selectedMenu === "orderHistory" ? "selected" : "menu-filter-container")} id="orderHistory" onClick={this.onMenuChange.bind(this, "orderHistory")}>
                            <p>Istorija porudžbina</p>
                            <i className="flat-icon">
                                <img src="images/small_img/history.png" alt="Ikonica za karticu istorija porudžbina" />
                            </i>
                            <i className="font-awesome">
                                <FontAwesomeIcon icon={faHistory} />
                            </i>
                        </div>
                        <div className={(this.state.selectedMenu === "favorites" ? "selected" : "menu-filter-container")} id="favorites" onClick={this.onMenuChange.bind(this, "favorites")}>
                            <p>Omiljeno</p>
                            <i className="flat-icon">
                                <img src="images/small_img/add.png" alt="Ikonica za karticu omiljeni lokali" />
                            </i>
                            <i className="font-awesome">
                                <FontAwesomeIcon icon={faStar} />
                            </i>
                        </div>
                        <div className={(this.state.selectedMenu === "addresses" ? "selected" : "menu-filter-container")} id="addresses" onClick={this.onMenuChange.bind(this, "addresses")}>
                            <p>Moje adrese</p>
                            <i className="flat-icon">
                                <img src="images/small_img/pin.png" alt="Ikonica za karticu moje adrese" />
                            </i>
                            <i className="font-awesome">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </i>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            // TODO: This code will be used intend of all map functions in return  
            // let asideMenuItems = [];
            // this.state.clientTypes.map((clientType) => {
            //     let ico = clientType.img.small === "" ? defaultImg : clientType.img;
            //     return (asideMenuItems.push(
            //         <AsideMenuItem
            //             selected={this.state.selectedClientType === clientType.id}
            //             id={clientType.id}
            //             name={clientType.name}
            //             img={ico}
            //             onClick={this.onTypeChange}
            //         />))
            // });
            return (
                <div className="aside-menu-container">
                    {this.state.clientTypes ?
                        <div className="aside-menu-food-type hidden-store-stand">
                            {/* Hidden div that start to show at 670px */}
                            <div className="aside-menu-header ">
                                <p>Lokali:</p>
                                <div className="selected-item-clone">
                                    {this.state.clientTypes.map((item) => {
                                        let ico = item.img.small === "" ? defaultImg : item.img;
                                        if (this.state.selectedClientType === item.id) {
                                            return (
                                                <>
                                                    <div className="selected-clone"
                                                        id={item.id} onClick={this.onTypeChange.bind(this, item.id)}>
                                                        <p>{item.name}</p>
                                                        <i><img src={ico.small} alt={ico.alt} /></i>
                                                    </div>
                                                </>
                                            );
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                </div>
                                <input type="checkbox" id="check-filter" checked={this.state.showAsideMenuChecked}
                                    onChange={this.onCheckBoxChange} />
                                <label htmlFor="check-filter" className="dropdown">
                                    <i>
                                        <img src="images/small_img/rotate-arrow.svg" alt="Padajuci meni ikonica" />
                                    </i>
                                </label>
                                <div className="aside-menu-dropdown">
                                    <h3 className="h3-hidden">Tip lokala:</h3>
                                    {this.state.clientTypes.map((item) => {
                                        let ico = item.img.small === "" ? defaultImg : item.img;
                                        return (
                                            <>
                                                <div className={((this.state.selectedClientType === item.id ? "selected" : "menu-filter-container "))}
                                                    id={item.id} onClick={this.onTypeChange.bind(this, item.id)}>
                                                    <p>{item.name}</p>
                                                    <i><img src={ico.small} alt={ico.alt} /></i>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="aside-menu-dropdown hidden-aside">
                                <h3 className="h3-hidden">Tip lokala:</h3>
                                {this.state.clientTypes.map((item) => {
                                    let ico = item.img.small === "" ? defaultImg : item.img;
                                    return (
                                        <>
                                            <div className={((this.state.selectedClientType === item.id ? "selected" : "menu-filter-container "))}
                                                id={item.id} onClick={this.onTypeChange.bind(this, item.id)}>
                                                <p>{item.name}</p>
                                                <i><img src={ico.small} alt={ico.alt} /></i>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        : null}
                    {this.state.listClient ?
                        <div className="store-stand-menu-container">
                            <h3>Meni:</h3>
                            <div className="store-stand-menu-content">
                                {Object.values(this.state.client.menus).length > 1 ?
                                    <div className={((this.state.selectedMenu === "all" ? "menu-selected" : "unselected-content "))}
                                        id={"all"} onClick={this.onMenuChange.bind(this, "all")}>
                                        <p>{"Svi artikli"}</p>
                                        <i>
                                            <img src={defaultImg.small} alt="Svi artikli" />
                                            <i className="yellow-circle"></i>
                                        </i>
                                    </div> : null}
                                {Object.values(this.state.client.menus).map((item) => {
                                    let showIt = false;
                                    Object.values(item.items).forEach(menuItem => {
                                        if (menuItem.showOnWebsite) {
                                            showIt = true;
                                        }
                                    });
                                    if (showIt) {
                                        return (
                                            <>
                                                <div className={((Number(this.state.selectedMenu) === item.index ? "menu-selected" : "unselected-content "))}
                                                    id={item.index} onClick={this.onMenuChange.bind(this, item.index)}>
                                                    <p>{item.name}</p>
                                                    <i>
                                                        <img src={((item.img.small || item.img.small === "") ? defaultImg.small : item.img.small)} alt={item.imgAlt} />
                                                        <i className="yellow-circle"></i>
                                                    </i>
                                                </div>
                                            </>
                                        );
                                    }
                                    else { return null; }

                                })}
                            </div>
                        </div> : <></>}
                </div>
            )
        }
    }
});
// This element will be used after CSS refactor
// var AsideMenuItem = createReactClass({
//     render: function () {
//         return (
//             <>
//                 <div className={(this.props.selected ? "menu-selected" : "unselected-content ")}
//                     id={this.props.index} onClick={this.props.onClick}>
//                     <p>{this.props.name}</p>
//                     <i>
//                         <img src={(this.props.img.small === "" ? defaultImg : this.props.img.small)} alt={this.props.img.alt} />
//                         <i className="yellow-circle"></i>
//                     </i>
//                 </div>
//             </>
//         );
//     }
// });


export default AsideMenu
