import enumWorkingState from '../model/enums/workingState';
import enumOrderPayer from "../model/enums/orderPayer";
import '../styles/Partners.css';
var createReactClass = require('create-react-class');

var DisplayContainer = createReactClass({
    getInitialState: function () {
        if (this.props.displayType === "client" && this.props.client) {
            return {
                workingTime: this.props.client.getCurrentActiveWorkingTime(),
                workingState: this.props.client.workingState,
            }
        }
        else if (this.props.displayType === "menu") {
            return {
                item: this.props.item
            }
        }
        else {
            return null;
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.item !== prevProps.item) {
            this.setState({
                item: this.props.item
            });
        }
    },
    render: function () {
        if (this.props.displayType === "client") {
            let coverImg = undefined;
            switch (this.props.client.workingState) {
                case enumWorkingState.CLOSED:
                    coverImg =
                        <div className="store-closed" onClick={this.props.onClick}>
                            <img src="./images/regular/Zatvoreno.svg" alt="Lokal zatvoren" />
                        </div>;
                    break;
                case enumWorkingState.BREAK:
                    coverImg =
                        <div className="store-closed" onClick={this.props.onClick}>
                            <img src="./images/regular/pauza.svg" alt="Lokal na pauzi" />
                        </div>;
                    break;

                default:
                    coverImg = undefined;
                    break;
            }
            if (this.props.client.isOverloaded && !coverImg) {
                coverImg =
                    <div className="store-closed" onClick={this.props.onClick}>
                        <img src="./images/regular/Prebukirano.svg" alt="Lokal prebukiran" />
                    </div>;
            }
            return (
                <>
                    {coverImg}
                    <div className="single-partner-display-unit" onClick={this.props.onClick}>
                        {this.props.client.orderPayer !== enumOrderPayer.USER ?
                            <>
                                {/* TODO: Make Content handler where all default values will be defined, so db will only  override it and
                                this checking will not be necessary*/}
                                {this.props.dbHandler.getSiteContent().images ?
                                    <>
                                        <img className="free-delivery-banner"
                                            src={this.props.dbHandler.getSiteContent().images.freeDelivery.normal}
                                            alt={this.props.dbHandler.getSiteContent().images.freeDelivery.alt} />
                                    </> :
                                    <>
                                        <img className="free-delivery-banner" src="./images/regular/besplatna1.svg" alt="Besplatna dostava baner" />
                                    </>}
                            </> : null}
                        <div className="single-dispaly-unit-patner-img">
                            <img src={(this.props.client.img.normal === "" ? "../images/shop.png" : this.props.client.img.normal)} alt="Profil slika lokala" />
                        </div>
                        <div className="display-unit-image">
                            <img src={(this.props.client.headerImg.normal === "" ? "../images/shop.png" : this.props.client.headerImg.normal)} alt="Naslovna slika lokala" />
                        </div>
                        <div className="single-partner-display-footer">
                            <h2>{this.props.client.name}</h2>
                            <p className="partner-address">{this.props.client.locations[0].address}</p>
                            <p className="partner-delivery">Dostava: {this.state.workingTime ? this.state.workingTime.toString(true) : "Ne radi"}</p>
                            {/* <h4>Za poneti:<br /> {this.state.workingTime ? this.state.workingTime.toString(true) : "Ne radi"}</h4> */}
                            {/* <h4 className="display-unit-tags">{getClientTypeNames(this.props.client.types, this.props.clientTypes).join(', ')}</h4> */}
                        </div>
                    </div>
                </>
            )
        }
        else if (this.props.displayType === "menu") {
            return (
                <div className="single-display-unit" onClick={() => { this.props.onClick(this.state.item, this.props.menu) }}>
                    <div className="display-unit-image">
                        {this.state.item.isImgFromWeb ? <div className="symbolic-photo">
                            <p>Slika je simbolična</p>
                        </div> : null}
                        {this.state.item.img.normal ?
                            <img src={this.state.item.img.normal} alt={this.state.item.img.alt} /> :
                            this.props.client && this.props.client.img.normal ?
                                <img src={this.props.client.img.normal} alt="Slika izabranog jela" /> :
                                <img src="../images/shop.png" alt="Slika izabranog jela" />}
                    </div>
                    <h4>{this.state.item.name}</h4>
                    <p>{this.state.item.description}</p>
                    <h4 className="display-unit-price">
                        {this.state.item.use_price ? "" + this.state.item.price : "Od " + this.state.item.getPriceFromSubItems()}
                        ‎‎&nbsp;RSD
                    </h4>
                </div>
            )
        }
        else {
            return (
                <div className="single-display-unit" onClick={this.props.onClick}>
                    <h2>No display type selected</h2>
                </div>
            )
        }
    }
});

export default DisplayContainer;