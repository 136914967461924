import Loader from './Loader.jsx';
import Success from '../common/Success.jsx';
import Error from '../common/Error.jsx';
import '../styles/DeliveryModal.css';
var createReactClass = require('create-react-class');

var ModalBodySubmit = createReactClass({
    getInitialState: function () {
        return ({
            waitingForRespond: this.props.waitingForRespond
        })
    },
    render: function () {
        if (this.props.waitingForRespond) {
            return (
                <div className="modal-body-form">
                    <Loader />
                </div>
            )
        }
        else if (!this.props.waitingForRespond && this.props.success) {
            return (
                <div className="modal-body-form">
                    <div className="modal-body-form-message">
                        <Success />
                        <h3>Zahtev uspešno poslat!</h3>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="modal-body-form">
                    <div className="modal-body-form-message">
                        <Error />
                    </div>
                    {this.props.errorMessage ? this.props.errorMessage : null /*Print error*/}
                </div>
            )
        }
    }
});

export default ModalBodySubmit;