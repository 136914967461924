import React from 'react';
import '../styles/Presentation.css';
var createReactClass = require('create-react-class');

var Presentation = createReactClass({
    render: function () {
        return (
            <div className="presentation-container">
                <div className="image-presentation-container">
                    <img src="./images/slika_par11.svg" alt="Par porucuje preko sajta deMango" className="kenburns-bottom" />
                </div>
                <div className="info-presentation-container">
                    <div className="position-one">
                        <img src="./images/destination.svg" alt="Dostava putanja" />
                    </div>
                    <div className="info-position">
                        <p className="head-one">Poručite iz </p>
                        <p className="head-two">udobnosti vašeg doma</p>
                        <div className="icon-div">
                            <div className="icon-one">
                                <img src="./images/express-delivery.svg" alt="Brza dostava" />
                                <p>Dostava porudžbine u roku od 1h</p>
                            </div>
                            <div className="icon-two">
                                <img src="./images/purchase-order.svg" alt="Jednostavno poručivanje" />
                                <p>Jednostavno poručivanje u par klikova</p>
                            </div>
                            <div className="icon-three">
                                <img src="./images/24-hours.svg" alt=" 24h dostava" />
                                <p>Mogućnost poručivanja 24/7</p>
                            </div>
                            <div className="icon-four">
                                <img src="./images/delivery-courier.svg" alt="20+ kurira" />
                                <p>Preko 20 <br /> kurira </p>
                            </div>
                        </div>
                    </div>
                    <div className="position-two">
                        <img src="./images/destination.svg" alt="Dostava putanja" />
                    </div>
                </div>
            </div>
        )
    }
});

export default Presentation
