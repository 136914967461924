import '../styles/SiteTestDiv.css';
import '../styles/MessageBox.css';
import { disableScroll, enableScroll } from '../common/effectsHelper';
var createReactClass = require('create-react-class');

var Questionnaire = createReactClass({
    getInitialState: function () {
        return {
            enableSubmitButton: false,
            question1: "",
            question2: "",
            question3: "",
            question4: "",
            question5: "",
            question6: "",
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    onChange: function (event) {
        let value = event.target.value;
        let name = event.target.name;
        this.setState({ [name]: value },
            () => {
                if (this.state.question1 &&
                    this.state.question2 &&
                    this.state.question3 &&
                    this.state.question4 &&
                    this.state.question5 &&
                    this.state.question6) {
                    this.setState({ details: value, enableSubmitButton: true });
                }
                else {
                    this.setState({ details: value, enableSubmitButton: false });
                }
            })
    },
    onClose: function () {
        let returnObject = {
            question1: this.state.question1,
            question2: this.state.question2,
            question3: this.state.question3,
            question4: this.state.question4,
            question5: this.state.question5,
            question6: this.state.question6,
        };
        this.props.onClick("survey", returnObject);
    },
    render: function () {
        return (
            <div className="message-box-container">
                <div className="message-box-content flex-column border-radius">
                    <div className="message-box-header">
                        <p>Anketa</p>
                    </div>
                    <div className="message-box-body">
                        <div className="questionnaire-container">
                            <div>
                                <p>1.Kako biste ocenili snalaženje na sajtu na skali od 1-5?<br />1 za veoma teško, 5 za veoma lako</p>
                                <form action="" className="question-form" id="question1" onChange={this.onChange}>
                                    <div className="form-input">
                                        <input type="radio" name="question1" id="veryHard" value="1" />
                                        <label htmlFor="veryHard">1</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question1" id="hard" value="2" />
                                        <label htmlFor="hard">2</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question1" id="soso" value="3" />
                                        <label htmlFor="soso">3</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question1" id="easy" value="4" />
                                        <label htmlFor="easy">4</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question1" id="veryEasy" value="5" />
                                        <label htmlFor="veryEasy">5</label>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <p>2.Šta Vam se najmanje dopalo na sajtu i šta je to što biste promenili?</p>
                                <textarea name="question2" id="question2" cols="40" rows="5" placeholder="Stavite 'Nemam komentar' ukoliko nemate zamerki"
                                    onChange={this.onChange} >{this.state.question2}</textarea>
                            </div>
                            <div>
                                <p>3.Da li ste ranije koristili usluge drugih sajtova za poručivanje?</p>
                                <textarea name="question3" id="question3" cols="40" rows="2"
                                    onChange={this.onChange}>{this.state.question3}</textarea>
                            </div>
                            <div>
                                <p>4.Ukoliko je Vaš odgovor na prethodno pitanje "Da", recite nam šta po Vama nedostaje na tim sajtovima ili ovom i šta biste želeli da vidite u nekom skorijem periodu? </p>
                                <textarea name="question4" id="question4" cols="40" rows="5"
                                    onChange={this.onChange}>{this.state.question4}</textarea>
                            </div>
                            <div>
                                <p>5.Kako biste ocenili dizajn sajta? 1 za majko mila, 5 za topčina:</p>
                                <form action="" className="question-form" onChange={this.onChange}>
                                    <div className="form-input">
                                        <input type="radio" name="question5" id="verybad" value="1" />
                                        <label htmlFor="verybad">1</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question5" id="bad" value="2" />
                                        <label htmlFor="bad">2</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question5" id="okay" value="3" />
                                        <label htmlFor="okay">3</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question5" id="good" value="4" />
                                        <label htmlFor="good">4</label>
                                    </div>
                                    <div className="form-input">
                                        <input type="radio" name="question5" id="verygood" value="5" />
                                        <label htmlFor="verygood">5</label>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <p>6.Imate li još nešto da dodate što nije pokriveno anketom? </p>
                                <textarea name="question6" id="question6" cols="40" rows="5"
                                    onChange={this.onChange}>{this.state.question6}</textarea>
                            </div>
                        </div>
                    </div>
                    <div className="message-box-footer">
                        <button className="btn-style-white"
                            onClick={this.props.onClick.bind(this, "surveyCancel")}>
                            Otkaži
                        </button>
                        <button className={("btn-style-white " + (this.state.enableSubmitButton ? "" : "disabled"))}
                            onClick={this.onClose}
                            disabled={!this.state.enableSubmitButton}>
                            Pošalji
                        </button>
                    </div>
                </div>
            </div>
        )
    }
});

export default Questionnaire
