import React from 'react';
import { timeToHoursMinutesDate } from '../common/DateTimeHelper';
import Feedback from '../common/Feedback';
import MessageBox from '../common/MessageBox';
import enumNotificationType from '../model/enums/notificationType';
import enumRequestState from '../model/enums/request_state';
import '../styles/OrderTracker.css';
var createReactClass = require('create-react-class');

const NotificationManager = createReactClass({
    getInitialState: function () {
        if (this.props.user) {
            this.props.dbHandler.initializeUserNotificationsListener(this.props.user.phone, this.onNewNotificationEvent);
        }
        return {
            user: this.props.user,
            notifications: [],
            showMessageBox: false,
            showOrderRatingModal: false,
            selectedNotification: null,
            // tone: this.props.tone ? this.props.tone : "mixkit-software-interface.wav",
            playing: false,
            enableNotifications: this.props.enableNotifications,
            enableNotificationSound: this.props.enableNotificationSound,
        };
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user, });
            if (this.props.user) {
                this.props.dbHandler.initializeUserNotificationsListener(this.props.user.phone, this.onNewNotificationEvent);
            }
        }
        if (this.props.muted !== prevProps.muted) {
            this.setState({ muted: this.props.muted, });
        }
        if (this.props.enableNotifications !== prevProps.enableNotifications) {
            this.setState({ enableNotifications: this.props.enableNotifications, });
        }
        if (this.props.enableNotificationSound !== prevProps.enableNotificationSound) {
            this.setState({ enableNotificationSound: this.props.enableNotificationSound, });
        }
    },
    onNewNotificationEvent: function (notifications) {
        // Sort them to make the oldest on the bottom ot the screen.
        const sorted = [].concat(notifications)
            .sort((a, b) => a.date < b.date ? 1 : -1);
        this.setState({ notifications: sorted });
        if (notifications >= this.state.notifications && notifications.length !== 0) {
            this.playAudio();
        }
    },
    onButtonClick: function (notification) {
        if (notification.orderState === enumRequestState.CANCELED) {
            this.setState({
                showMessageBox: true,
                messageBoxContent: notification.cancellationReason,
                messageBoxTitle: "Razlog otkazivanja",
                selectedNotification: notification,
            });
        }
        else if (notification.orderState === enumRequestState.DONE) {
            this.setState({
                showOrderRatingModal: true,
                selectedNotification: notification,
            });
        }
    },
    clearAllNotifications: function () {
        this.state.notifications.forEach(notification => {
            this.props.dbHandler.deleteNotification(notification.id, this.state.user.phone);
        });
    },
    generateNotification: function (notification) {
        switch (notification.type) {
            case enumNotificationType.ORDER_SEEN:
                return <Notification dbHandler={this.props.dbHandler}
                    notification={notification}
                    userId={this.state.user.phone}
                    img="./images/regular/choices.svg"
                    alt="Vaša porudžbina je pregledana"
                    // info={"Živ čovek je pogledao vašu porudžbinu broj " + notification.orderId.split('_')[0]}
                    info={"Vaša porudžbina  " +
                        (notification.clientName ? "iz '" + notification.clientName + "' je pregledana."
                            : "broj " + notification.orderId.split('_')[0] + " je pregledana.")}
                    showButton={false} />
            case enumNotificationType.ORDER_CHANGED_STATE:
                switch (notification.orderState) {
                    case enumRequestState.PENDING_FOR_CLIENT:
                    case enumRequestState.PENDING_FOR_DELIVERY:
                        return <Notification dbHandler={this.props.dbHandler}
                            notification={notification}
                            userId={this.state.user.phone}
                            img="./images/regular/choices.svg"
                            alt="Lokal prihvatio porudžbinu"
                            info={(notification.clientName ? "'" + notification.clientName + "'"
                                : "Lokal") + "je prihvatio vašu porudžbinu broj " + notification.orderId.split('_')[0] + "."}
                            showButton={false} />;
                    case enumRequestState.IN_PROGRESS:
                        return <Notification dbHandler={this.props.dbHandler}
                            notification={notification}
                            userId={this.state.user.phone}
                            img="./images/regular/delivery-box.svg"
                            alt="Kurir je preuzeo porudžbinu"
                            // Ne mozemo za sada reci da je kurir preuzeo jer se ovdemisli da je samo kurir obavesten.
                            //info={"Kurir je preuzeo vašu porudžbinu broj " + notification.orderId.split('_')[0]}
                            // info={"Kurir je preuzeo vašu porudžbinu " +
                            //     (notification.clientName ? "iz '" + notification.clientName + "'."
                            //         : "broj " + notification.orderId.split('_')[0])}
                            info={"Vaša porudžbina " +
                                (notification.clientName ? "iz '" + notification.clientName + "' "
                                    : "broj " + notification.orderId.split('_')[0]) + "je u procesu dostave."
                                + (notification.deliveryTime ? " Približno vreme dostave je: " + notification.deliveryTime + " minuta." : "")}
                            showButton={false} />;
                    case enumRequestState.DONE:
                        return <Notification dbHandler={this.props.dbHandler}
                            notification={notification}
                            userId={this.state.user.phone}
                            img="./images/regular/orderdelivered.svg"
                            alt="Porudžbina je dostavljena"
                            info={"Vaša porudžbina pod brojem " + notification.orderId.split('_')[0] + " je dostavljena."}
                            showButton={true}
                            buttonValue="Ocenite porudžbinu"
                            onClick={this.onButtonClick} />;
                    case enumRequestState.CANCELED:
                        return <Notification dbHandler={this.props.dbHandler}
                            notification={notification}
                            userId={this.state.user.phone}
                            img="./images/regular/ordercancel.svg"
                            alt="Porudžbina otkazana"
                            info={"Vaša porudžbina pod brojem " + notification.orderId.split('_')[0] + " je otkazana."}
                            showButton={true}
                            buttonValue="Vidite razlog"
                            onClick={this.onButtonClick} />;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    },
    playAudio: function () {
        if (this.state.enableNotificationSound) {
            const audioEl = document.getElementsByClassName("audio-element")[0]
            if (audioEl && !this.state.playing) {
                this.setState({ playing: true }, () => {
                    audioEl.pause();
                    audioEl.load();
                    audioEl.currentTime = 0;
                    //const playPromise = 
                    audioEl.play().then(() => { }).catch((error) => {
                        console.log(error);
                    }).finally(() => { this.setState({ playing: false }); })
                });
            }
        }
    },
    render: function () {
        if (this.state.enableNotifications) {
            return (
                <div className="order-tracker-container">
                    <div id="order-tracker-content" className="order-tracker-content-box">
                        {this.state.showMessageBox ?
                            <MessageBox
                                content={this.state.messageBoxContent}
                                onClick={() => {
                                    this.props.dbHandler.deleteNotification(this.state.selectedNotification.id, this.state.user.phone);
                                    this.setState({
                                        showMessageBox: false,
                                        selectedNotification: null,
                                        messageBoxContent: "",
                                        messageBoxTitle: "",
                                    });
                                }}
                                title={this.state.messageBoxTitle}
                            /> : null}
                        {this.state.showOrderRatingModal ?
                            <Feedback
                                dbHandler={this.props.dbHandler}
                                notification={this.state.selectedNotification}
                                onClose={(success) => {
                                    if (success) {
                                        this.props.dbHandler.deleteNotification(this.state.selectedNotification.id, this.state.user.phone);
                                    }
                                    this.setState({
                                        showOrderRatingModal: false,
                                        showMessageBox: true,
                                        messageBoxContent: "Hvala Vam na izdvojenom vremenu.",
                                        messageBoxTitle: "Info",
                                    });
                                }}
                            /> : null}
                        {this.state.notifications.map((notification) => {
                            return (
                                <>{this.generateNotification(notification)}</>
                            );
                        })}
                    </div>
                    {this.state.notifications.length > 1 ?
                        <button className="clear-all" onClick={this.clearAllNotifications}>
                            Ukloni sve
                        </button> : null}
                    <audio className="audio-element">
                        <source src={this.state.tone}></source>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )
        }
        else { return null; }
    }
});

const Notification = createReactClass({
    getInitialState: function () {
        return {
            img: this.props.img,
            alt: this.props.alt,
            info: this.props.info,
            notification: this.props.notification,
        };
    },
    componentDidMount() {
        // Scroll to the bottom of the Notification div.
        const contentElement = document.getElementById('order-tracker-content');
        const clientHeight = contentElement.clientHeight * 2;
        contentElement.scrollTo({ top: clientHeight });
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.img !== prevProps.img) {
            this.setState({ img: this.props.img, alt: this.props.alt });
        }
        if (this.props.info !== prevProps.info) {
            this.setState({ info: this.props.info });
        }
        if (this.props.notification !== prevProps.notification) {
            this.setState({ notification: this.props.notification });
        }
    },
    render: function () {
        return (
            <div className="track-order-content">
                <div className="order-tracker-head">
                    <p>Obaveštenje: {timeToHoursMinutesDate(this.props.notification.date)}</p>
                    <i onClick={() => {
                        this.props.dbHandler.deleteNotification(this.state.notification.id, this.props.userId);
                    }}>&times;</i>
                </div>
                <div className="track-order-body">
                    <p>{this.state.info}</p>
                    <img src={this.state.img} alt={this.state.alt} />
                    {this.props.showButton ?
                        <button className="order-feedback" onClick={() => { this.props.onClick(this.state.notification); }}>
                            {this.props.buttonValue}
                        </button> : null}
                </div>
                <audio className="audio-element">
                    <source src="../audio/mixkit-software-interface.wav"></source>
                    Your browser does not support the audio element.
                </audio>
            </div>
        );
    }
})

export default NotificationManager
