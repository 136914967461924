import '../styles/StoreStand.css';
import '../styles/MainStyle.css';
import '../styles/Partners.css';
import DisplayContainer from './DisplayContainer';
import NoData from '../common/NoData.jsx';
var createReactClass = require('create-react-class');

var StoreStandClientItems = createReactClass({
    render: function () {
        return (
            this.props.menus ?
                <>
                    {Object.values(this.props.menus).map((menu) => {
                        let menuItemContainers = [];
                        Object.values(menu.items).forEach(menuItem => {
                            if (menuItem.showOnWebsite) {
                                menuItemContainers.push(
                                    <div className="store-stand-display-body-content">
                                        <DisplayContainer
                                            client={this.props.client}
                                            menu={menu}
                                            item={menuItem}
                                            displayType="menu"
                                            onClick={this.props.onClick}
                                        />
                                    </div>);
                            }
                        });
                        if (menuItemContainers && menuItemContainers.length > 0) {
                            return (
                                <>
                                    {/* TODO: Change this code to make it prettier. This menu name should be displayed in one line. */}
                                    <div className="store-stand-display-body-label">
                                        {(Object.values(this.props.menus).length > 1 && Object.values(menu.items).length > 0) ?
                                            <p>
                                                {menu.name}
                                            </p> : <></>}
                                    </div>
                                    <div className="store-stand-display-body-container">
                                        {menuItemContainers}
                                    </div>
                                </>
                            )
                        } else { return null; }

                    })}
                </> :
                <>
                    <div className="no-data-container">
                        <NoData />
                    </div>
                </>
        );
    }
});

export default StoreStandClientItems