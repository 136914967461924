import React from 'react';
import '../styles/CookieMessage.css';
import Cookies from 'js-cookie';
var createReactClass = require('create-react-class');

var CookieMessage = createReactClass({
    getInitialState: function () {
        return {
            cookieExists: Cookies.get('demangoCookieInfo')
        }
    },
    onClose: function () {
        Cookies.set('demangoCookieInfo', true);
        this.setState({ cookieExists: true })
    },
    render: function () {
        if (this.state.cookieExists) {
            return (<></>);
        }
        else {
            return (
                <div className="cookie-message-container">
                    <div className="cookie-message-container-content">
                        <div className="cookie-message-image">
                            <img src="./images/regular/cookies.svg" alt="Slika kolačića" />
                        </div>
                        <div className="cookie-message-description">
                            <p>
                                deMango koristi kolačiće, kako bismo Vam obezbedili fantastičan ugođaj prilikom korišćenja servisa.
                                Daljim korišćenjem sajta smatraćemo da ste saglasni sa korišćenjem kolačića.
                                &nbsp;<a href onClick={this.props.onClick ? this.props.onClick : () => { console.log("On click function is not set."); }}> Saznajte više</a>
                            </p>
                        </div>
                        <div className="close-cookie-message" on onClick={this.onClose}>
                            <span><p>&times;</p></span>
                        </div>
                    </div>
                </div>
            )
        }
    }
})

export default CookieMessage
