import '../styles/SiteTestDiv.css';
import '../styles/MessageBox.css';
import { disableScroll, enableScroll } from '../common/effectsHelper';
var createReactClass = require('create-react-class');

var BugReport = createReactClass({
    getInitialState: function () {
        return {
            enableSubmitButton: false,
            page: "Početna stranica",
            browser: "",
            device: "",
            details: "",
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    onChange: function (event) {
        let value = event.target.value;
        let id = event.target.id;

        switch (id) {
            case "page":
                this.setState({ page: value });
                break;
            case "browser":
                this.setState({ browser: value });
                break;
            case "device":
                this.setState({ device: value });
                break;
            case "details":
                if (value && value.length > 20) {
                    this.setState({ details: value, enableSubmitButton: true });
                }
                else {
                    this.setState({ details: value, enableSubmitButton: false });
                }

                break;
            default:
                break;
        }
    },
    onClose: function () {
        let returnObject = {
            page: this.state.page,
            browser: this.state.browser,
            device: this.state.device,
            details: this.state.details,
        };
        this.props.onClick("bugReport", returnObject);
    },
    render: function () {
        return (
            <div className="message-box-container">
                <div className="message-box-content flex-column border-radius">
                    <div className="message-box-header">
                        <p>Prijava baga</p>
                    </div>
                    <div className="message-box-body">
                        <div className="bug-report-container">
                            <div>
                                <p>Na kojoj stranici ste primetili bag?</p>
                                <br />
                                <form>
                                    <label for="page">Izaberite stranicu:</label><br />
                                    <select name="page" id="page">
                                        <option value="landing">Početna stranica</option>
                                        <option value="partner">Stranica sa lokalima</option>
                                        <option value="storeStand">Stranica odabranog lokala </option>
                                        <option value="myProfile">Moj profil stranica</option>
                                    </select>
                                </form>
                            </div>
                            <br />
                            <div>
                                <p>Koji web pretraživač ste koristili prilikom testiranja sajta?</p>
                                <input type="text" name="" id="browser" placeholder="npr. Google Chrome"
                                    value={this.state.browser} onChange={this.onChange} />
                            </div>
                            <br />
                            <div>
                                <p>Koji uređaj ste koristili prilikom testiranja sajta?</p>
                                <input type="text" name="" id="device" placeholder="Unesite telefon, tablet ili računar"
                                    value={this.state.device} onChange={this.onChange} />
                            </div>
                            <br />
                            <div>
                                <p>Molimo Vas da napišite detaljno korake za reprodukciju baga.</p>
                                <textarea name="" id="details" cols="25" rows="5"
                                    value={this.state.details} onChange={this.onChange}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="message-box-footer">
                        <button className="btn-style-white"
                            onClick={() => { this.props.onClick("bugReportCancel") }}>
                            Otkaži
                        </button>
                        <button className={("btn-style-white " + (this.state.enableSubmitButton ? "" : "disabled"))}
                            onClick={this.onClose}
                            disabled={!this.state.enableSubmitButton}>
                            Prijavi bag
                        </button>
                    </div>
                </div>
            </div>
        )
    }
});

export default BugReport
