/* eslint-disable react/no-direct-mutation-state */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { disableScroll, enableScroll } from '../common/effectsHelper';
import '../styles/StoreItemDisplayModal.css';
var createReactClass = require('create-react-class');

var StoreItemDisplayModal = createReactClass({
    getInitialState: function () {
        const _subItemMaps = this.props.itemToShow.generateSubItemMaps();
        this.props.itemToShow.unselectAllItems(_subItemMaps.group2subitem, _subItemMaps.group2priceTrigger);
        this.unselectSharedMenuItems();
        return {
            item: this.props.itemToShow,
            subItemsMap: _subItemMaps.group2subitem,
            maxSelectionPerGroup: _subItemMaps.group2maxSelection,
            priceSelectionTrigger: _subItemMaps.group2priceTrigger,
            excludedGroups: [],
            selectedMenuItems: {},
            sharedMenuItems: {},
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    unselectSharedMenuItems() {
        Object.values(this.props.client.menus).forEach(menu => {
            if (menu.useMeInOtherItems) {
                Object.values(menu.items).forEach((menuItem) => {
                    menuItem.selected = false;
                });
            }
        });
    },
    onSubitemSelection: function (subItem, group) {
        if (!subItem.selected && this.state.excludedGroups.includes(subItem.group)) {
            return;
        }
        if (!subItem.isRadioButton) {
            subItem.selected = !subItem.selected;
        }
        else {
            group.forEach(groupSubItem => {
                if (groupSubItem !== subItem) {
                    groupSubItem.selected = false;
                }
                subItem.selected = true;
            });
        }

        // If it's menu item that needs to be added to cart.
        if (subItem.menuItem) {
            if (subItem.selected) {
                this.state.selectedMenuItems[subItem.name] = subItem;
            }
            else {
                this.state.selectedMenuItems[subItem.name] = undefined;
            }
        }

        const maxSelection = this.state.maxSelectionPerGroup[subItem.group];
        const priceTrigger = this.state.priceSelectionTrigger[subItem.group];
        if (maxSelection || priceTrigger) {
            let numberOfSelectedPerGroup = 0;
            let numberOfUsedPricePerGroup = 0;
            group.forEach(groupSubItem => {
                if (groupSubItem.selected) {
                    numberOfSelectedPerGroup++;
                }
                if (priceTrigger && groupSubItem.usePrice) {
                    numberOfUsedPricePerGroup++;
                }
            });
            if (subItem.selected) {
                if (maxSelection && Number(numberOfSelectedPerGroup) > Number(maxSelection)) {
                    subItem.selected = false;
                }
                if (subItem.selected && priceTrigger && Number(numberOfSelectedPerGroup) >= Number(priceTrigger)) {
                    subItem.usePrice = true;
                }
            }
            else { // Deselect logic
                if (priceTrigger) {
                    if (subItem.usePrice) {
                        numberOfUsedPricePerGroup--;
                        subItem.usePrice = false;
                    }
                    const shouldBeSelected = numberOfSelectedPerGroup - priceTrigger + 1;
                    group.forEach((groupSubItem) => {
                        if (groupSubItem.usePrice && numberOfUsedPricePerGroup > shouldBeSelected) {
                            groupSubItem.usePrice = false;
                            numberOfUsedPricePerGroup--;
                        }
                    });
                }
            }
        }
        let updateExcludedGroups = false
        let excludedGroups = [];
        if (subItem.exclusive) {
            excludedGroups = this.state.item.getAllExcludedGroups();
            Object.values(this.state.item.items).forEach(subItem => {
                if (excludedGroups.includes(subItem.group) && subItem.selected) {
                    subItem.selected = false;
                    if (this.state.priceSelectionTrigger[subItem.group]) {
                        subItem.usePrice = false;
                    }
                }
            });
            updateExcludedGroups = true;
        }

        if (updateExcludedGroups) {
            this.setState({
                excludedGroups: excludedGroups
            });
        }
        else {
            this.setState({
                changed: true
            });
        }
    },
    increaseQuantity: function () {
        this.state.item.itemCount++;
        this.setState({ render: true });
    },
    decreaseQuantity: function () {
        if (this.state.item.itemCount > 1) {
            this.state.item.itemCount--;
            this.setState({ render: true });
        }
    },
    generateSubitemBody: function () {
        const groupedSubItems = [];
        // Iterate through all subitems and add them to collection.
        Object.keys(this.state.subItemsMap).forEach((groupName, groupNameIndex) => {
            const oneGroupItems = [];
            if (this.state.subItemsMap[groupName]) {
                this.state.subItemsMap[groupName].forEach((subItem, index) => {
                    subItem.isRadioButton ?
                        oneGroupItems.push(
                            <div className="single-item-input-container">
                                <input type="radio"
                                    name={"subitemGroup" + groupNameIndex + "_" + index}
                                    id={"subitemGroup" + groupNameIndex + "_" + index}
                                    checked={subItem.selected}
                                    onChange={() => { this.onSubitemSelection(subItem, this.state.subItemsMap[groupName]) }} />
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="input-black-circle">
                                    <i></i>
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="input-yellow-circle">
                                    <i></i>
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index}>
                                    {" " + subItem.name}
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="charged-add-ons">
                                    <p>&nbsp;{"  " + (subItem.price ? (" (" + subItem.price + " RSD)") : "")}</p>
                                </label>
                            </div>
                        ) :
                        oneGroupItems.push(
                            <div className="single-item-input-container">
                                <input type="checkbox" name={"subitemGroup" + groupNameIndex + "_" + index}
                                    id={"subitemGroup" + groupNameIndex + "_" + index}
                                    checked={subItem.selected}
                                    onChange={() => { this.onSubitemSelection(subItem, this.state.subItemsMap[groupName]) }} />
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="input-black-box">
                                    <i><FontAwesomeIcon icon={faCheck} /></i>
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="input-yellow-circle">
                                    <i></i>
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index}>
                                    {" " + subItem.name}
                                </label>
                                <label htmlFor={"subitemGroup" + groupNameIndex + "_" + index} className="charged-add-ons">
                                    <p>&nbsp;{"  " + ((subItem.price && subItem.usePrice) ? (" (" + subItem.price + " RSD)") : "")}</p>
                                </label>
                            </div>
                        )
                });
                if (oneGroupItems.length > 0) {
                    groupedSubItems.push(
                        <>
                            <h3>{groupName}</h3>
                            <div className="single-item-portion-size">
                                {oneGroupItems}
                            </div>
                        </>
                    )
                }
            }
        });
        // Make subitems from shared Menu
        Object.values(this.props.client.menus).forEach(menu => {
            if (menu.useMeInOtherItems && this.props.selectedMenu !== menu) {
                const sharedSubitems = [];
                Object.values(menu.items).forEach((menuItem, menuItemIndex) => {
                    this.state.sharedMenuItems[menuItem.name] = menuItem;
                    menuItem.menuItem = true;
                    sharedSubitems.push(
                        <div className="single-item-input-container">
                            <input type="checkbox" name={"subitemGroup_" + menuItem.name + "_" + menuItemIndex}
                                id={"subitemGroup_" + menuItem.name + "_" + menuItemIndex}
                                checked={menuItem.selected}
                                onChange={() => { this.onSubitemSelection(menuItem, this.state.subItemsMap[menu.name]) }} />
                            <label htmlFor={"subitemGroup_" + menuItem.name + "_" + menuItemIndex} className="input-black-box">
                                <i><FontAwesomeIcon icon={faCheck} /></i>
                            </label>
                            <label htmlFor={"subitemGroup_" + menuItem.name + "_" + menuItemIndex} className="input-yellow-circle">
                                <i></i>
                            </label>
                            <label htmlFor={"subitemGroup_" + menuItem.name + "_" + menuItemIndex}>
                                {" " + menuItem.name}
                            </label>
                            <label htmlFor={"subitemGroup_" + menuItem.name + "_" + menuItemIndex} className="charged-add-ons">
                                <p>&nbsp;{"  " + ((menuItem.price && menuItem.use_price) ? (" (" + menuItem.price + " RSD)") : "")}</p>
                            </label>
                        </div>
                    );
                });
                if (sharedSubitems.length > 0) {
                    groupedSubItems.push(
                        <>
                            <h3>{menu.name}</h3>
                            <div className="single-item-portion-size">
                                {sharedSubitems}
                            </div>
                        </>
                    )
                }
            }
        });

        return groupedSubItems.length > 0 ?
            <div className="single-item-body">
                {groupedSubItems}
            </div> : <></>
    },
    render: function () {
        return (
            <div className="store-item-display-container">
                <div className="store-single-item-display">
                    <div className="single-item-header">
                        <div className="single-item-image">
                            {this.state.item.img.normal ?
                                <img src={this.state.item.img.normal} alt={this.state.item.img.alt} /> :
                                this.props.client && this.props.client.img.normal ?
                                    <img src={this.props.client.img.normal} alt="Slika izabranog jela" /> :
                                    <img src="images/testImages/jelo.png" alt="Slika izabranog jela" />}
                        </div>
                        <div className="single-item-name">
                            <div className="single-item-span">
                                <h2>{this.state.item.name}</h2>
                                <span onClick={() => { this.props.onClose() }}>
                                    <h1>&times;</h1>
                                </span>
                            </div>
                            <p>{this.state.item.description}</p>
                        </div>
                    </div>
                    {this.generateSubitemBody()}
                    <div className="single-item-footer">
                        <h3>Ukupno: {this.state.item.getCurrentPrice(Object.values(this.state.sharedMenuItems))} RSD</h3> <br />
                        {/*  TODO: This should stay under the real price. */}
                        {this.props.client.packingFee ?
                            <h5>+ pakovanje: {this.props.client.packingFee} RSD</h5> : null}
                        <div className="single-item-footer-quantity">
                            <button className="quantity-button" onClick={this.decreaseQuantity}>-</button>
                            <h1>x{this.state.item.itemCount}</h1>
                            <button className="quantity-button" onClick={this.increaseQuantity}>+</button>

                        </div>
                        <button className="single-item-add-to-cart"
                            onClick={() => { this.props.onClose(true, Object.values(this.state.selectedMenuItems)) }}>Dodaj u korpu</button>
                    </div>
                </div>
            </div>
        )
    }
});

export default StoreItemDisplayModal
