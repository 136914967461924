import LoaderSmall from '../common/LoaderSmall';
import LoginModal from './LoginModal';
import '../styles/Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhoneAlt, faTruck, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { disableScroll, enableScroll } from '../common/effectsHelper';
var createReactClass = require('create-react-class');

var Header = createReactClass({
    getInitialState: function () {
        return {
            show_modal: false,
            user: this.props.user,
            showMenu: false,
            authInitDone: this.props.authInitDone,
            isGold: this.props.user ? this.props.user.gold : false,
            displayName: this.props.user ? this.props.user.name.split(" ")[0] : "",
        }
    },
    componentDidUpdate: function (prevProps) {
        if ((this.props.user !== prevProps.user) ||
            (this.props.user && this.props.user.areEqual && !this.props.user.areEqual(prevProps))) {
            this.setState({
                user: this.props.user,
                isGold: this.props.user ? this.props.user.gold : false,
                displayName: this.props.user ? this.props.user.name.split(" ")[0] : "",
            });
        }
        if (this.props.authInitDone !== prevProps.authInitDone) {
            this.setState({
                authInitDone: this.props.authInitDone
            });
        }
    },
    componentDidMount() {
        this.toggleWindowScroll()
        // window.scrollTo(0, 0);
    },
    btnOnclick: function () {
        if (this.state.user) {
            this.props.authManager.logOut();
            this.props.onClick("");
        }
        else {
            let value = !this.state.show_modal;
            this.setState({ show_modal: value });
        }
        this.toggleWindowScroll(false);
    },
    onModalClose: function () {
        this.setState({
            show_modal: false,
            showMenu: false
        });
        this.toggleWindowScroll(false);
    },
    spanOnclick: function () {
        var value = this.state.show_modal;
        this.setState({ show_modal: value });
    },
    onCheckBoxChange: function (event) {
        let newValue = event.target.checked;
        switch (event.target.id) {
            case "check":
                this.setState({ showMenu: newValue });
                this.toggleWindowScroll(newValue);
                break;
            default:
                break;
        }
    },
    toggleWindowScroll: function (toggleOff) {
        if (toggleOff) {
            disableScroll();
        }
        else {
            enableScroll();
        }
    },
    onMenuItemClick: function (id) {
        this.toggleWindowScroll(false);
        if (this.props.onClick) {
            this.props.onClick(id);
        }
        else {
            console.log("OnClick function is not set!");
        }
        this.setState({ showMenu: false });
    },
    render: function () {
        let buttonText = this.state.user ? "Izloguj se" : "Uloguj se";
        return (
            <>
                <nav>
                    {/* ukoliko je clan zlatan slika je demangogold.svg, ako nije, ostaje postojeca demango2.svg */}
                    {/* ukoliko je clan zlatan  novogodisnja slika je deMangoSantaGold.svg, ako nije onda je deMangoSanta.svg */}

                    {/* TODO: Make Content handler where all default values will be defined, so db will only  override it and
                    this checking will not be necessary*/}
                    {this.props.dbHandler.getSiteContent().images ?
                        <>
                            <img src={(this.state.isGold ?
                                this.props.dbHandler.getSiteContent().images.logoGold.normal :
                                this.props.dbHandler.getSiteContent().images.logo.normal)}
                                alt={this.props.dbHandler.getSiteContent().images.logo.alt}
                                className="deMango-logo" onClick={() => { this.onMenuItemClick("") }} />
                        </> :
                        <>
                            <img src={(this.state.isGold ? "images/regular/deMangoSantaGold.svg" : "images/regular/deMangoSanta.svg")}
                                alt="deMango logo" className="deMango-logo" onClick={() => { this.onMenuItemClick("") }} />
                        </>}
                    <input type="checkbox" id="check" onChange={this.onCheckBoxChange} checked={this.state.showMenu} />
                    <label htmlFor="check" className="checkbtn">
                        <i><FontAwesomeIcon icon={faBars} /></i>
                    </label>
                    <div className="navigation-list-container">
                        <ul>
                            <li>
                                <a href onClick={() => { this.onMenuItemClick("") }}><span>Povratak na početnu stranu</span>
                                    <i className="navbar-icon"><FontAwesomeIcon icon={faHome} /></i>
                                    Početna
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => { this.onMenuItemClick("how_to") }}><span>Preusmeravanje na cenovnik</span>
                                    <i className="navbar-icon"><FontAwesomeIcon icon={faTruck} /></i>
                                    <p className="kolorado-delivery">Cenovnik dostave</p>
                                    <p className="delivery">Cenovnik</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => { this.onMenuItemClick("contact") }}><span>Kontakt brojevi i forma</span>
                                    <i className="navbar-icon"><FontAwesomeIcon icon={faPhoneAlt} /></i>
                                    Saradnja
                                </a>
                            </li>
                        </ul>
                        <div className="logged-in-user-container">
                            {this.state.user ?
                                <div className="logged-in-user ">
                                    <p className="loged-in-user-name" onClick={() => { this.onMenuItemClick("myProfile") }}>{this.state.displayName}</p>
                                    <span className="loged-in-user-icon" onClick={() => { this.onMenuItemClick("myProfile") }}>
                                        <img src="images/regular/profileIcon.svg" alt="Ikonica za moj profil" onClick={() => { this.onMenuItemClick("myProfile") }} />
                                        <i className="my-profile-icon"><FontAwesomeIcon icon={faUserAlt} /></i>
                                        <p className="loged-in-user-my-profile">Moj profil</p>
                                    </span>

                                </div> : null}
                            {this.state.authInitDone ?
                                <>
                                    <button onClick={this.btnOnclick} className="log-button">
                                        <i><FontAwesomeIcon icon={faSignOutAlt} /></i>
                                        {buttonText}
                                    </button>
                                </> :
                                <>
                                    <div className="navbar-loader">
                                        <LoaderSmall />
                                    </div>
                                </>}

                        </div>
                    </div>
                </nav>
                <div className="modal-container" id="container">
                    {this.state.show_modal ?
                        <LoginModal
                            onClose={this.onModalClose}
                            authManager={this.props.authManager}
                            dbHandler={this.props.dbHandler} />
                        : null}
                </div>
            </>
        )

    }
})

export default Header;
