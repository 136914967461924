import { disableScroll, enableScroll } from '../common/effectsHelper';
import '../styles/TermsConditions.css';
import '../styles/KoloradoPage.css';
import Loader from './Loader';
var createReactClass = require('create-react-class');

var TermsConditions = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.getFooterContent(this.onDbLoadFinish);
        return {
            selectedMenu: this.props.selectedMenu ? this.props.selectedMenu : "shoppingBySteps",
            content: {},
            loading: true,
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    onDbLoadFinish: function (menuContent) {
        this.setState({
            content: menuContent,
            loading: false,
        });
    },
    onClick: function (event) {
        const id = event.currentTarget.id;
        this.setState({
            selectedMenu: id,
        });
    },
    getContent: function () {
        return ({
            __html: this.state.content[this.state.selectedMenu] ? this.state.content[this.state.selectedMenu] : "No Content"
        });
    },
    render: function () {
        return (
            <div className="terms-container">
                <div className="terms-content">
                    <aside className="aside-footer-questions">
                        <div className="site-help">
                            <p className="de-mango-terms-title">Pomoć</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "shoppingBySteps" ? "selected" : ""))}
                                id="shoppingBySteps"
                                onClick={this.onClick}>Kupovina po koracima</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "whatWeDeliver" ? "selected" : ""))}
                                id="whatWeDeliver"
                                onClick={this.onClick}>Šta sve dostavljamo?</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "whyToRegister" ? "selected" : ""))}
                                id="whyToRegister"
                                onClick={this.onClick}>Pravilnik o utiscima</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "whatAreDemangoCoins" ? "selected" : ""))}
                                id="whatAreDemangoCoins"
                                onClick={this.onClick}>Šta su deMango bodovi?</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "whatIsColorado" ? "selected" : ""))}
                                id="whatIsColorado"
                                onClick={this.onClick}>Šta je Kolorado021 dostava?</p>

                        </div>
                        <div className="customer-service">
                            <p className="de-mango-terms-title">Korisnički servis</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "contactAndCollaboration" ? "selected" : ""))}
                                id="contactAndCollaboration"
                                onClick={this.onClick}>Kontakt i saradnja</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "termsAndConditions" ? "selected" : ""))}
                                id="termsAndConditions"
                                onClick={this.onClick}>Uslovi korišćenja</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "privatePolicy" ? "selected" : ""))}
                                id="privatePolicy"
                                onClick={this.onClick}>Politika privatnosti</p>
                            <p className={("terms-info-link " + (this.state.selectedMenu === "needGraphicalDesign" ? "selected" : ""))}
                                id="needGraphicalDesign"
                                onClick={this.onClick}>Potreban Vam je dizajner?</p>
                        </div>
                    </aside>
                    <div className="aside-footer-answers">
                        <div className="answer-header">
                            <button onClick={this.props.onClose} >X</button>
                        </div>
                        <div className="answer-body">
                            <div className="neki">
                                {this.state.loading ? <Loader /> : <p dangerouslySetInnerHTML={this.getContent()}></p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
});

export default TermsConditions
