import '../styles/MessageBox.css';
import createReactClass from 'create-react-class';
import { disableScroll, enableScroll } from './effectsHelper';

var MessageBox = createReactClass({
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    render: function () {
        return (
            <div className="message-box-container">
                <div className="message-box-content flex-column border-radius">
                    <div className="message-box-header">
                        <p>{this.props.title}</p>
                        {this.props.showX ? <i onClick={this.props.onClick.bind(this, false)}>&times;</i> : null}
                    </div>
                    <div className="message-box-body">
                        <p>{this.props.content}</p>
                        {this.props.secondContent}
                    </div>
                    <div className="message-box-footer">
                        {this.props.showCancel ?
                            <button className={("btn-style-white " + (this.props.disableCancel ? "disabled" : ""))}
                                onClick={this.props.onClick.bind(this, false)}
                                disabled={this.props.disableCancel}>
                                Otkaži
                            </button> : null}
                        <button className={("btn-style-white " + (this.props.disableButton ? "disabled" : ""))}
                            onClick={this.props.onClick.bind(this, true)}
                            disabled={this.props.disableButton}>
                            U redu
                        </button>
                    </div>
                </div>
            </div>
        )
    }
});

export default MessageBox;