import '../styles/PartnerMenu.css';
import DeliveryModal from "./DeliveryModal";
var createReactClass = require('create-react-class');
var React = require('react');

// TODO: This component will be used for advertising.
var PartnerMenu = createReactClass({
    getInitialState: function () {
        return {
            show_modal: false
        }
    },
    btnOnclick: function () {
        this.setState({ show_modal: true });
    },
    onModalClose: function () {
        this.setState({ show_modal: false });
    },
    spanOnclick: function () {
        var value = this.state.show_modal;
        this.setState({ show_modal: value });
    },
    render: function () {
        return (
            <>
                <div className="demnago-baner">
                    <div className="moto">
                        <h1 className="moto-h1">Sve što poželiš... <br /> ...mi donosimo</h1>
                    </div>
                    <div className="moto-button">
                        <button onClick={this.btnOnclick} className="button" id="modalButton"><span>Kurirske usluge</span></button>
                    </div>
                </div>
                <div className="modal-container" id="container">
                    {this.state.show_modal ?
                        <DeliveryModal
                            authManager={this.props.authManager}
                            onClose={this.onModalClose}
                            user={this.props.user}
                            dbHandler={this.props.dbHandler}
                        />
                        : null}
                </div>
            </>
        );
    }
});

export default PartnerMenu
