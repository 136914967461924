import '../styles/MessageBox.css';
import createReactClass from 'create-react-class';
import '../styles/OrderInfoMadal.css';
import '../styles/MainStyle.css';
import '../styles/ShoppingCart.css';
import Feedback from '../common/Feedback';
import enumRequestState from '../model/enums/request_state';
import { disableScroll, enableScroll } from '../common/effectsHelper';
import MessageBox from '../common/MessageBox';

var OrderInfoModal = createReactClass({
    getInitialState: function () {
        return {
        }
    },
    componentDidMount() {
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    shouldShowOrderRatingButton: function () {
        return !this.props.order.hasRating && this.props.order.state === enumRequestState.DONE;
    },
    render: function () {
        if (this.state.showRatingModal) {
            return (
                <Feedback
                    onClose={() => {
                        this.setState({
                            showRatingModal: false,
                            showMessageBox: true,
                            messageBoxContent: "Hvala Vam na izdvojenom vremenu.",
                            messageBoxTitle: "Info",
                        })
                    }}
                    dbHandler={this.props.dbHandler}
                    user={this.props.user}
                    order={this.props.order} />
            );
        }
        if (this.state.showMessageBox) {
            return (
                <MessageBox
                    content={this.state.messageBoxContent}
                    onClick={() => {
                        this.setState({
                            showMessageBox: false,
                            messageBoxContent: "",
                            messageBoxTitle: "",
                        });
                    }}
                    title={this.state.messageBoxTitle}
                />
            );
        }
        else {
            const hasCart = !this.props.order.cart.isEmpty();
            return (
                <div className="order-info-container">
                    <div className="order-info-content">
                        <div className="order-info-header">
                            <p>{this.props.order.senderAddress.name}</p>
                        </div>
                        <div className="order-info-body">
                            <div className="order-left">
                                <div className="order-number">
                                    <p>PORUDŽBINA BROJ: {this.props.order.partOfID()}</p>
                                </div>
                                {hasCart ?
                                    <>
                                        {
                                            this.props.order.cart.items.map((cartItem) => {
                                                if (cartItem) {
                                                    return (
                                                        <>
                                                            <div className="shopping-cart-item-container">
                                                                <div className="shopping-cart-item-image">
                                                                    {cartItem.img.normal ?
                                                                        <img src={cartItem.img.normal} alt={cartItem.img.alt} /> :
                                                                        this.props.order.cart.client.img.normal ?
                                                                            <img src={this.props.order.cart.client.img.normal} alt="Slika izabranog jela" /> :
                                                                            <img src="images/testImages/jelo.png" alt="Slika izabranog jela" />}
                                                                </div>
                                                                <div className="shopping-cart-item-info">
                                                                    <div className="single-cart-item-info-header">
                                                                        <p className="cart-item-name">{cartItem.name}</p>
                                                                        <p>X{cartItem.itemCount}</p>
                                                                    </div>
                                                                    <div className="single-cart-item-info-footer">
                                                                        <p>{cartItem.getCurrentPrice()} RSD</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </> :
                                    <div className="courier-delivery-container">
                                        <div className="courier-delivery">
                                            <div className="courier-delivery-sender">
                                                <p className="bold">POŠILJALAC:</p>
                                                <p>
                                                    {this.props.order.senderAddress.name} <br />
                                                    {this.props.order.senderAddress.address}
                                                </p>
                                            </div>
                                            <div className="courier-delivery-receiver">
                                                <p className="bold">PRIMALAC:</p>
                                                <p>
                                                    {this.props.order.receiverAddress.name}<br />
                                                    {this.props.order.receiverAddress.address}
                                                </p>
                                            </div>
                                            <div className="courier-delivery-comment">
                                                <p className="bold">KOMENTAR:</p>
                                                <p>{this.props.order.comment}</p>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="order-right">
                                <p className="delivery-title">PODACI ZA DOSTAVU:</p>
                                <div className="receiver-info">
                                    <img src="./images/regular/graymap.svg" alt=" Lokacija ikonica" />
                                    <div className="address">
                                        <p>{this.props.order.receiverAddress.name}</p>
                                        <p>{this.props.order.receiverAddress.address}</p>
                                    </div>
                                </div>
                                <div className="price-info">
                                    <p className="delivery-title">CENA:</p>
                                    {hasCart ?
                                        <div className="space-between">
                                            <p>Porudžbina:</p>
                                            <p>{this.props.order.cart.getCurrentPrice()} RSD</p>
                                        </div>
                                        : null}
                                    <div className="space-between">
                                        <p>Dostava:  </p>
                                        <p>{this.props.order.deliveryCost} RSD</p>
                                    </div>
                                    <div className="space-between">
                                        <p className="total">Ukupno:</p>
                                        <p className="total">
                                            {Number(this.props.order.cart.getCurrentPrice()) + Number(this.props.order.deliveryCost)} RSD
                                        </p>
                                    </div>
                                </div>
                                <div className="order-canceled">
                                    {
                                        this.props.order.cancellationReason ?
                                            <div className="cancel-reason-container">
                                                <p className="cancel-title">Razlog otkazivanja:</p>
                                                <p>{this.props.order.cancellationReason}</p>
                                            </div>
                                            : null}
                                </div>
                                <div className="leave-feedback">
                                    {/* It will be shown only if order is not rated and when it's in DONE state. */}
                                    {this.shouldShowOrderRatingButton() ?
                                        <button
                                            className="leave-feedback-btn"
                                            onClick={() => { this.setState({ showRatingModal: true }) }}>
                                            Oceni dostavu
                                        </button>
                                        : null}
                                </div>
                                {/* Dugme za ponavljanje porudžbine */}
                                {/* <div className="repeat-order">
                                    {this.props.order.state === enumRequestState.DONE ?
                                        <button
                                            className="repeat-order-btn">
                                            Ponovi porudžbinu
                                        </button>
                                        :
                                        null
                                    }
                                </div> */}
                            </div>
                        </div>
                        <div className="order-info-footer">
                            <button className="btn-style-white" onClick={this.props.onClose}>U redu</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
});

export default OrderInfoModal;