/* eslint-disable react/no-direct-mutation-state */
import MessageBox from "./MessageBox";
import BugReport from "../siteTestMaterial/BugReport";
import Questionnaire from "../siteTestMaterial/Questionnaire";
import EmailService from "../common/EmailService.js";
import '../styles/SiteTestDiv.css';
var createReactClass = require('create-react-class');

var TestingDiv = createReactClass({
    getInitialState: function () {
        return {
            user: this.props.user,
            showBugReport: false,
            showSurvey: false,
            showMessageBox: false,
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user });
        }
    },
    onClose: function (dialogName, retObject) {
        retObject.name = this.state.user.name;
        retObject.phone = this.state.user.phone;
        retObject.email = this.state.user.email;
        switch (dialogName) {
            case "bugReport":
                this.setState({ showBugReport: false, });
                EmailService.emailBug(retObject, () => { this.setState({ showMessageBox: true, }); });
                break;
            case "bugReportCancel":
                this.setState({ showBugReport: false, });
                break;
            case "survey":
                EmailService.emailSurvey01(retObject, () => { this.setState({ showMessageBox: true, }); });
                this.setState({ showSurvey: false, });
                this.state.user.doneSurveys["demango_01"] = true;
                this.state.user.giftPoints = Number(this.state.user.giftPoints) + 20;
                this.props.dbHandler.updateUserDB(this.state.user, null)
                break;
            case "surveyCancel":
                this.setState({ showSurvey: false, });
                break;
            case "info":
                this.setState({ showMessageBox: false, });
                break;
            default:
                break;
        }
    },
    onCLick: function (dialogName) {
        switch (dialogName) {
            case "bugReport":
                this.setState({ showBugReport: true, });
                break;
            case "survey":
                this.setState({ showSurvey: true, });
                break;
            default:
                break;
        }
    },
    render: function () {
        if (this.state.user) {
            return (
                <>
                    <div className="site-test-container">
                        <button className="question-test-button" onClick={this.onCLick.bind(this, "survey")}>
                            <img src="./images/regular/question-mark.svg" alt="Sajt test dugme" />
                        </button>
                        <div className="tooltip">
                            <span className="tooltip-questionery">Popuni upitnik</span>
                        </div>
                    </div>
                    {this.state.showBugReport ?
                        <>
                            <BugReport onClick={this.onClose} />
                        </> : null}
                    {this.state.showSurvey ?
                        <>
                            {this.state.user.doneSurveys["demango_01"] ?
                                <>
                                    <MessageBox onClick={this.onClose.bind(this, "surveyCancel")}
                                        title="Info"
                                        content="Odgovorili ste na sve trenutno dostupne ankete. Hvala Vam na izdvojenom vremenu!" />
                                </> :
                                <>
                                    <Questionnaire onClick={this.onClose} />
                                </>}
                        </> : null}
                    {this.state.showMessageBox ?
                        <>
                            <MessageBox onClick={this.onClose.bind(this, "info")}
                                title="Info"
                                content="Vaša informacija nam je od velikog značaja! Hvala na izdvojenom vremenu!" />
                        </> : null}
                </>
            )
        }
        else {
            return (<> </>)
        }
    }
})

export default TestingDiv
