import '../styles/ShoppingCart.css';
import '../styles/MainStyle.css';
import ShoppingCartItem from "./ShoppingCartItem";
import DeliveryModal from "./DeliveryModal";
var createReactClass = require('create-react-class');

var ShoppingCart = createReactClass({
    getInitialState: function () {
        return {
            storeCart: this.props.storeCart,
            showDeliveryModal: false,
            client: this.props.client,
            user: this.props.user
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.storeCart !== prevProps.storeCart) {
            this.setState({ storeCart: this.props.storeCart });
            this.onCartChange();
        }
        if (this.props.client !== prevProps.client) {
            this.setState({ client: this.props.client });
        }
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user });
        }
    },
    emptyCart: function () {
        this.state.storeCart.emptyCart();
        this.setState({ render: true });
        this.onCartChange();
    },
    makeOrder: function () {
        this.setState({ showDeliveryModal: true });
        this.onCartChange();
    },
    onModalClose: function (orderSentSuccess) {
        if (orderSentSuccess) {
            this.state.storeCart.emptyCart();
        }
        this.setState({ showDeliveryModal: false });
        this.onCartChange();
    },
    onRemoveElement: function () {
        this.setState({ removeElement: true });
        this.onCartChange();
    },
    onCartChange: function () {
        if (this.props.onCartChange) {
            this.props.onCartChange();
        }
        else {
            console.log("On Cart change function is not provided!");
        }
    },
    render: function () {
        return (
            <div className="shopping-cart-container">
                <div className="shopping-cart-header">
                    <p className="header-shopping-cart-text">Korpa</p>
                    <button className={("empty-shopping-cart-button yellow" + (this.state.storeCart.items && this.state.storeCart.items.length > 0 ? "" : " disabled"))}
                        onClick={this.emptyCart}
                        disabled={!(this.state.storeCart.items && this.state.storeCart.items.length > 0)}>
                        <img src="./images/empty-cart.png" alt="Isprazni korpu ikonica" className="empty-cart-regular" />
                        <img src="./images/small_img/cart.svg" alt="Isprazni korpu ikonica" className="empty-cart-responsive" />
                    </button>
                </div>
                <div className="shopping-cart-body">
                    {this.state.storeCart.items && this.state.storeCart.items.length > 0 ?
                        <>
                            {this.state.storeCart.items.map((item) => {
                                return (
                                    <ShoppingCartItem
                                        item={item}
                                        cart={this.state.storeCart}
                                        onCountChange={() => { this.setState({ countChanged: true }); this.onCartChange(); }}
                                        client={this.state.client}
                                        onRemove={this.onRemoveElement}
                                    />
                                );
                            })}

                        </> :
                        <div className="empty-shopping-cart-container">
                            <img src="./images/regular/empty-cart.svg" alt="Dodaj u korpu ikonica" />
                            <p>Korpa je prazna</p>
                        </div>}
                </div>
                <div className="shopping-cart-footer">
                    <p>Ukupno: {this.state.storeCart.getCurrentPrice()} RSD</p>
                    <button className={("shopping-cart-button" + (this.state.storeCart.items && this.state.storeCart.items.length > 0 ? "" : " disabled"))}
                        onClick={this.makeOrder}
                        disabled={!(this.state.storeCart.items && this.state.storeCart.items.length > 0)}>
                        Poruči
                    </button>
                </div>
                {this.state.showDeliveryModal ?
                    <DeliveryModal
                        onClose={this.onModalClose}
                        onlyReceiver={true}
                        client={this.state.client}
                        user={this.state.user}
                        cart={this.state.storeCart}
                        dbHandler={this.props.dbHandler}
                        authManager={this.props.authManager}
                    /> : null}
            </div>
        )
    }
});

export default ShoppingCart
