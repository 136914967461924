import '../styles/CallForOrder.css';
var createReactClass = require('create-react-class');

var CallForOrder = createReactClass({
    render: function () {
        return (
            <div className="call-container">
                <div className="main-content">
                    <div className="de-mango-top">
                        <h2>Kolorado <span>021</span> dostava</h2>
                    </div>
                    <div className="phone-div">
                        <div className="first-div">
                            <h2>065/574-1592</h2>
                            <h2>065/574-1593</h2>
                        </div>
                        <div className="middle-div">
                            <img src="./images/callagent.svg" alt="Kolorado 021 dispecer" />
                        </div>
                        <div className="third-div">
                            <h2>065/574-1592 <img src="./images/viber.svg" alt="Viber ikonica" /></h2>
                            <h2>065/4063662 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            <h2 className="hidden-third-div-element">065/574-1592</h2>
                            <h2 className="hidden-third-div-element">065/574-1593</h2>
                        </div>
                    </div>
                    <div className="de-mango-bottom">
                        <p>
                            Bilo da želite da se informišete ili da zakažete dostavu,
                            uvek nas možete kontaktirati na neki od gore navedenih telefona.
                            Za nas ne postoji radno vreme, tu smo za sva vaša pitanja i porudžbine u bilo koje doba dana  ili noći!
                        </p>
                    </div>
                </div>
            </div>
        )
    }
});

export default CallForOrder
