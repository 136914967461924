import EmailService from "../common/EmailService.js";
import MessageBox from "../common/MessageBox";
import BugReport from "../siteTestMaterial/BugReport";
import '../styles/DeMangoInfo.css';
var createReactClass = require('create-react-class');

var DeMangoInfo = createReactClass({
    getInitialState: function () {
        return {
            user: this.props.user,
            showBugReport: false,
            showMessageBox: false,
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user });
        }
    },
    onClose: function (dialogName, retObject) {
        if (retObject) {
            retObject.name = this.state.user.name;
            retObject.phone = this.state.user.phone;
            retObject.email = this.state.user.email;
        }
        switch (dialogName) {
            case "bugReport":
                this.setState({ showBugReport: false, });
                if (retObject) {
                    this.setState({ showMessageBox: true, });
                    EmailService.emailBug(retObject, () => { this.setState({ showMessageBox: true, }); });
                }
                break;
            case "bugReportCancel":
                this.setState({ showBugReport: false, });
                break;
            case "info":
                this.setState({ showMessageBox: false, });
                break;
            default:
                break;
        }
    },
    onCLick: function (event) {
        const id = event.currentTarget.id;
        switch (id) {
            case "bugReport":
                this.setState({ showBugReport: true, });
                break;

            default:
                if (this.props.onTermsClick) {
                    this.props.onTermsClick(id);
                }
                else {
                    console.log("On terms click function is not set.");
                }
                break;
        }
    },
    render: function () {
        return (
            <div className="de-mango-info-container" id="contact-section">
                <div className="de-mango-info-social-media">
                    <img src="./images/regular/demango2.svg" alt="deMango logo" className="de-mango-logo" />
                    <p>Zapratite nas</p>
                    <div className="social-media-container">
                        <a href="https://www.instagram.com/_de_mango/">
                            <img src="./images/regular/instagram.svg" alt="Instagram logo" className="social-media" />
                        </a>
                        <a href="https://www.facebook.com/deMango.novisad">
                            <img src="./images/regular/facebook.svg" alt="Facebook logo" className="social-media" />
                        </a>
                    </div>
                </div>
                <div className="de-mango-info-steps">
                    <p className="de-mango-info-title">Pomoć</p>
                    <a href className="de-mango-info-link" id="shoppingBySteps" onClick={this.onCLick}><p>Kupovina po koracima</p></a>
                    <a href className="de-mango-info-link" id="whatWeDeliver" onClick={this.onCLick}><p>Šta sve dostavljamo?</p></a>
                    <a href className="de-mango-info-link" id="whyToRegister" onClick={this.onCLick}><p>Pravilnik o utiscima</p></a>
                    <a href className="de-mango-info-link" id="whatAreDemangoCoins" onClick={this.onCLick}><p>Šta su deMango bodovi?</p></a>
                    <a href className="de-mango-info-link" id="whatIsColorado" onClick={this.onCLick}><p>Šta je Kolorado021 dostava?</p></a>
                </div>
                <div className="de-mango-info-terms">
                    <div className="de-mango-terms">
                        <p className="de-mango-info-title">Korisnički servis</p>
                        <a href className="de-mango-info-link" id="contactAndCollaboration" onClick={this.onCLick}><p>Kontakt i saradnja</p></a>
                        <a href className="de-mango-info-link" id="termsAndConditions" onClick={this.onCLick}><p>Uslovi korišćenja</p></a>
                        <a href className="de-mango-info-link" id="privatePolicy" onClick={this.onCLick}><p>Politika privatnosti</p></a>
                        <a href className="de-mango-info-link" id="needGraphicalDesign" onClick={this.onCLick}><p>Potreban Vam je grafički dizajner?</p></a>
                    </div>
                    <div className="de-mango-contact">
                        <p className="company-name">deMango</p>
                        <p>Telefon: +381 65 420 90 30</p>
                        <p>E-mail: marketing@demango.rs</p>
                    </div>
                </div>
                <div className="de-mango-info-bug-report">
                    <p className="de-mango-info-title">Primetili ste grešku na sajtu?</p>
                    <span className="description">
                        <p>
                            deMango će Vam biti zahvalan ako prijavite svaku grešku koju uočite prilikom
                            korišćenja sajta.
                        </p>
                    </span>
                    <span className="description">
                        <p>
                            Registrovani korisnici koji prijave grešku deMango timu i ako naš tim utvrdi
                            prijavljenu grešku, korisnik će biti nagradjen <p className="yellow-letters">deMango bodovima.</p>
                        </p>
                    </span>
                    <button className="report-bug-button" id="bugReport" onClick={this.onCLick}>Prijavi grešku</button>
                </div>
                {this.state.showMessageBox ?
                    <MessageBox onClick={() => { this.onClose("info", null) }}
                        title="Info"
                        content="Vaša informacija nam je od velikog značaja! Hvala na izdvojenom vremenu!" />
                    : null}
                {this.state.showBugReport ?
                    <BugReport onClick={this.onClose} />
                    : null}
            </div>
        )
    }
});

export default DeMangoInfo
