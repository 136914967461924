
export class OrderRating {
    orderId: string;
    clientId: string;
    clientName: string;
    userEmail: string;
    userName: string;
    userPhone: string;
    deliveryRating: string;
    clientRating: string;
    comment: string;
    date: Date;

    constructor() {
        this.orderId = "";
        this.clientId = "";
        this.clientName = "";
        this.userEmail = "";
        this.userName = "";
        this.userPhone = "";
        this.deliveryRating = "";
        this.clientRating = "";
        this.comment = "";
        this.date = new Date();
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.orderId) {
                this.orderId = jsonObject.orderId;
            }
            if (jsonObject.clientId) {
                this.clientId = jsonObject.clientId;
            }
            if (jsonObject.clientName) {
                this.clientName = jsonObject.clientName;
            }
            if (jsonObject.userEmail) {
                this.userEmail = jsonObject.userEmail;
            }
            if (jsonObject.userName) {
                this.userName = jsonObject.userName;
            }
            if (jsonObject.userPhone) {
                this.userPhone = jsonObject.userPhone;
            }
            if (jsonObject.deliveryRating) {
                this.deliveryRating = jsonObject.deliveryRating;
            }
            if (jsonObject.clientRating) {
                this.clientRating = jsonObject.clientRating;
            }
            if (jsonObject.comment) {
                this.comment = jsonObject.comment;
            }
            if (jsonObject.date) {
                this.date = new Date(jsonObject.date);
            }
        }
    }

    toJason(): any {
        let retObj: any = {
            orderId: this.orderId,
            clientId: this.clientId,
            clientName: this.clientName,
            userEmail: this.userEmail,
            userName: this.userName,
            userPhone: this.userPhone,
            deliveryRating: this.deliveryRating,
            clientRating: this.clientRating,
            comment: this.comment,
            date: this.date.getTime(),
        };

        return retObj;
    }
}

export default OrderRating;
