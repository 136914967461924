import '../styles/MyProfile.css';
import '../styles/MainStyle.css';
import '../styles/ShoppingCart.css';
var createReactClass = require('create-react-class');

var MyProfileContent = createReactClass({
    getInitialState: function () {
        if (this.props.address) {
            return ({
                address: this.props.address,
                deleted: false
            });
        }
        else if (this.props.order) {
            return ({
                order: this.props.order,
                deleted: false
            });
        }
        else if (this.props.client) {
            return ({
                client: this.props.client,
                deleted: false
            });
        }
    },
    onDelete: function () {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
        else {
            console.log("On delete function is not set!");
        }
        this.setState({ deleted: true });
    },
    render: function () {
        if (this.state.deleted) {
            return (<></>)
        }
        else if (this.state.address) {
            return (
                <>
                    <div className="my-profile-box-column">
                        {/* div za adresu dobija klase my-profile-single-display i my-address-size*/}
                        <div className="my-profile-single-display my-address-size">
                            <div className="image-div">
                                {/* Ima i slika graymap.svg */}
                                <img src="images/regular/location.svg" alt="Adresa profil slika" className="address-img" />
                            </div>
                            <div className="info-div">
                                <span>
                                    <h4>{this.state.address.name}</h4>
                                    {/* <h5>Novi Sad</h5> */}
                                </span>
                                <h5>{this.state.address.address}</h5>
                                {/* Nista od ovoga nema u modelu */}
                                {/* <p>Sprat: <b>11</b></p> */}
                                {/* <p>Prezime:</p> */}
                                {/* <h5>Janjic</h5> */}
                                <p>Broj telefona:</p>
                                <h5>{this.state.address.phone}</h5>
                            </div>
                        </div>
                        <div className="my-profile-footer">
                            {/* <button className="my-profile-button update">
                                <img src="images/small_img/pencil.png" alt="Izmeni adresu ikonica" />
                                <p>Izmeni</p>
                            </button> */}
                            <button className="my-profile-button delete" onClick={this.onDelete}>
                                <img src="images/small_img/trash.svg" alt="Izbriši adresu ikonica" />
                                <p>Izbrisi</p>
                            </button>

                        </div>
                    </div>
                </>
            );
        }
        else if (this.state.order) {
            const hasCart = !this.state.order.cart.isEmpty();
            return (
                <>
                    <div className="my-profile-box-column"
                        onClick={() => {
                            if (this.props.onClick)
                                this.props.onClick(this.state.order)
                        }}>
                        <div className="my-profile-order-display my-order-size">
                            <div className="my-profile-my-order">
                                {/* TODO: Maybe it shoul display a date?  */}
                                <h3 className="store-name">{this.state.order.senderAddress.name}</h3>
                                <div className="my-order-items">
                                    {hasCart ?
                                        <>
                                            {
                                                this.state.order.cancellationReason ?
                                                    <div className="cancel-reason-container">
                                                        <p className="cancel-title">Razlog otkazivanja:</p>
                                                        <p>{this.state.order.cancellationReason}</p>
                                                    </div>
                                                    :
                                                    this.state.order.cart.items.map((cartItem) => {
                                                        if (cartItem) {
                                                            return (
                                                                <div className="shopping-cart-item-container">
                                                                    <div className="shopping-cart-item-image">
                                                                        {cartItem.img.normal ?
                                                                            <img src={cartItem.img.normal} alt={cartItem.img.alt} /> :
                                                                            this.state.order.cart.client.img.normal ?
                                                                                <img src={this.state.order.cart.client.img.normal} alt="Slika izabranog jela" /> :
                                                                                <img src="images/testImages/jelo.png" alt="Slika izabranog jela" />}
                                                                    </div>
                                                                    <div className="shopping-cart-item-info">
                                                                        <div className="single-cart-item-info-header">
                                                                            <p className="cart-item-name">{cartItem.name}</p>
                                                                            <p>X {cartItem.itemCount}</p>
                                                                        </div>
                                                                        <div className="single-cart-item-info-footer">
                                                                            <p>{cartItem.getCurrentPrice()} RSD</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })
                                            }
                                        </> :
                                        <div className="couried-delivery-container">
                                            {
                                                this.state.order.cancellationReason ?
                                                    <div className="cancel-reason-container">
                                                        <p className="cancel-title">Razlog otkazivanja:</p>
                                                        <p>{this.state.order.cancellationReason}</p>
                                                    </div>
                                                    :
                                                    <div className="couried-delivery">
                                                        <div className="couried-delivery-sender">
                                                            <p className="bold">Pošiljalac:</p>
                                                            <p>
                                                                {this.state.order.senderAddress.name} <br />
                                                                {this.state.order.senderAddress.address}
                                                            </p>
                                                        </div>
                                                        <div className="couried-delivery-receiver">
                                                            <p className="bold">Primalac:</p>
                                                            <p>
                                                                {this.state.order.receiverAddress.name}<br />
                                                                {this.state.order.receiverAddress.address}
                                                            </p>
                                                        </div>
                                                        <div className="couried-delivery-comment">
                                                            <p className="bold">Komentar:</p>
                                                            <p>{this.state.order.comment}</p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>}
                                </div>
                                {hasCart ?
                                    <>
                                        <p>Ukupno: {this.state.order.cart.getCurrentPrice()} RSD</p>
                                    </> :
                                    <>
                                    </>}
                                <div className={("order-status " + this.state.order.getStringStateValue(true))}>
                                    {this.state.order.getStringStateValue()}
                                </div>
                                {/* <button className="order-button"> Ponovi porudžbinu</button> */}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else if (this.state.client) {
            return (
                <>
                    <div className="my-profile-box-column">
                        <div className="my-profile-single-display my-favorite-size" onClick={this.props.onClick.bind(this, this.state.client)}>
                            <div className="image-div">
                                <img src={this.state.client.img.normal} alt={this.state.client.img.alt} className="store-img" />
                            </div>
                            <div className="info-div">
                                <h4>{this.state.client.name}</h4>
                                <h5>{this.state.client.locations[0].address}</h5>
                                {/* <p>Novi Sad</p> This will be supported by adding regions! */}

                                <span className="favorite-store">
                                    <p>Dodato u omiljene</p>
                                    <img src="images/small_img/star-checked.png" alt="Dodato u omiljene ikonica" />
                                </span>

                                {/* Da nismo rekli da ce sve porudzbine biti u kartici "Istorija porudzbina?" */}

                                {/* Ovaj span se prikazuje kad korisnik treba da otvori prikaz porudzbina */}
                                {/* <span className="down-span">
                                    <p>Vidi porudžbine</p>
                                    <img src="images/small_img/down-arrow.png" alt="Vidi porudžbine strelica" />
                                </span> */}
                                {/* Ovaj span se prikazuje kad korisnik treba da zatvori prikaz porudzbina*/}
                                {/* <span className="up-span">
                                <p>Sakrij porudžbine</p>
                                <img src="images/small_img/up-arrow.png" alt="Vidi porudžbine strelica" />
                            </span> */}
                            </div>
                        </div>
                        <div className="my-profile-footer">
                            <button className="my-profile-button delete" onClick={this.onDelete}>
                                <img src="images/small_img/trash.svg" alt="Izbriši adresu ikonica" />
                                <p>Izbrisi</p>
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        else {
            return (
                <>
                    <div className="my-profile-box-column">
                        UNSUPPORTED
                    </div>
                </>
            );
        }
    }
});

export default MyProfileContent
